// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableWidth {
  width: 100%;
  background-color: white;
}

.minimumTable-height .rdt_TableBody {
  height: 420px;
  overflow: auto;
}

.maximumTable-height .rdt_TableBody {
  height: 500px;
  overflow: auto;
}

.transparent-button-new {
  border: 1px solid #1d3d70;
  font-weight: 500;
  width: 138.88px;
  height: 28px;
  color: #1d3d70;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.editBtn {
  border-radius: var(--Border-Radius-4-px, 4px);
  border: 1px solid #1d3d70;
  background: #fff;
  color: #1d3d70;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  display: inline-flex;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.editBtn:hover {
  background: #1d3d70;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/CampaignList/campaignList.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uBAAA;AACF;;AAEE;EACE,aAAA;EACA,cAAA;AACJ;;AAIE;EACE,aAAA;EACA,cAAA;AADJ;;AAKA;EACE,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,6CAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,YAAA;AAFF","sourcesContent":[".tableWidth {\n  width: 100%;\n  background-color: white;\n}\n.minimumTable-height {\n  .rdt_TableBody {\n    height: 420px;\n    overflow: auto;\n  }\n}\n\n.maximumTable-height {\n  .rdt_TableBody {\n    height: 500px;\n    overflow: auto;\n  }\n}\n\n.transparent-button-new {\n  border: 1px solid #1d3d70;\n  font-weight: 500;\n  width: 138.88px;\n  height: 28px;\n  color: #1d3d70;\n  background: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 6px;\n}\n\n.editBtn {\n  border-radius: var(--Border-Radius-4-px, 4px);\n  border: 1px solid #1d3d70;\n  background: #fff;\n  color: #1d3d70;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  display: inline-flex;\n  height: 28px;\n  justify-content: center;\n  align-items: center;\n}\n\n.editBtn:hover {\n  background: #1d3d70;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
