import AdlibraryCardBody from "src/components/AdlibraryCardBody/AdlibraryCardBody"
import AdlibraryCardHeader from "src/components/AdlibraryCardHeader/AdlibraryCardHeader"
import { useEffect, useRef, useState } from "react"
import { deleteSpecificAds, updateSpecificAds } from "src/components/AdlibraryCardHeader/api-editAd"
import { Alert, Button, Modal } from "rsuite"
import { fetchAllAds } from "src/components/AdlibraryCardBody/api-adList"
import { Constants } from "src/common/constants"
import "./Adlibrary.scss"
import PreviewAdModal from "src/common/uploadsModal/PreviewAdModal"

const Adlibrary = (props: any) => {
  const [selectedRows, setSelectedRows] = useState({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [adList, setAdList] = useState<any>([])
  const [filteredList, setFilteredList] = useState<any>([])
  const [load, setLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [adType, setAdType] = useState("")
  const [adStatus, setAdStatus] = useState("")
  const [openPreviewAd, setOpenPreviewAd] = useState(false)
  const [functionSelected, setFunctionSelected] = useState<"edit" | "delete" | "">("")
  const [deleteCreds, setDeleteCreds] = useState<{ ad_eid: string }>({ ad_eid: "" })
  const formRef: any = useRef(null)

  const [searchText, setSearchText] = useState("")
  const [typeState, setTypeState] = useState("")
  const [statusState, setStatusState] = useState("")

  const [totalRows, setTotalRows] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { hideOptions, onSelectedRowsChange, pixelStatus } = props

  const handleRowNameSelected = (state: any) => {
    if (typeof state === "object") {
      setSelectedRows(state)
      setOpenPreviewAd(true)
    }
  }

  const handleRowSelected = (state: any, type: string) => {
    if (typeof state === "object") {
      setSelectedRows(state)
    }

    setAdType(type)
    setAdStatus(state?.status)

    if (onSelectedRowsChange) {
      onSelectedRowsChange(state?.selectedRows)
    }
  }

  const handleClose = () => {
    setOpenPreviewAd(false)
  }

  const handleDelete = async (ad_eid: string) => {
    try {
      setIsDeleteModalOpen(true)
      setDeleteCreds({
        ad_eid: ad_eid
      })
      // await deleteSpecificAds(apiKey, eid)
      // Alert.success("Delete Successfully")
      // getAllAds()
    } catch (error) {
      console.error("Error deleting ads:", error)
    }
  }

  const confirmDelete = async () => {
    try {
      await deleteSpecificAds(deleteCreds.ad_eid)
      Alert.success("Delete Successfully")
      getAllAds(1, pageSize)
    } catch (error) {
      console.error("Error deleting ads:", error)
    } finally {
      setIsDeleteModalOpen(false)
    }
  }

  const handlePageChange = (page: number) => {
    getAllAds(page, pageSize)
  }

  const handlePerPageChange = async (newPerPage: number, page: number) => {
    setPageSize(newPerPage)
    getAllAds(page, pageSize)
  }

  const handleSubmit = async (ad_eid: string, formValue: any) => {
    setIsLoading(true)
    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }
    setIsLoading(true)

    let payload

    payload = {
      name: formValue.name,
      headline: formValue.headline,
      message: formValue.message,
      destination_url: formValue.destination_url,
      brand_name: formValue.brand_name,
      call_to_action:formValue.selectedOption
    }

    const response: any = await updateSpecificAds(ad_eid, payload)

    if (response.status === 200) {
      setIsLoading(false)
      setOpen(false)
      Alert.success("Updated Successfully")
      // setTimeout(() => {
      //   getAllAds()
      // }, 1000)
      getAllAds(1, pageSize)
    } else {
      Alert.error("Server Down")
    }
  }

  const getAllAds = async (currentPage: number, pageSize: number) => {
    const sort_by = "name" + " asc"
    try {
      setLoad(true)

      const data: any = await fetchAllAds(currentPage, pageSize, sort_by)

      setTotalRows(data.response.count)
      setAdList(data.response.results)
      setFilteredList(data.response.results)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (statusState === null) {
      setFilteredList(adList)
    } else if (typeState === null) {
      setFilteredList(adList)
    } else {
      const filtered = adList.filter(
        (data: any) =>
          data?.status?.toLowerCase().includes(statusState?.toLowerCase()) && data?.type?.toLowerCase().includes(typeState?.toLowerCase())
      )

      setFilteredList(filtered)
    }
  }, [statusState, typeState])

  useEffect(() => {
    const filtered = adList.filter((data: any) => data?.name?.toLowerCase().includes(searchText?.toLowerCase()))
    setFilteredList(filtered)
  }, [searchText])

  return (
    <div className='AdLibraryParent'>
      <p className='adtext' style={{ padding: "20px 0px" }}>
        Ad Library
      </p>
      <Modal className='__generic_modal' backdrop={"static"} show={isDeleteModalOpen} onHide={() => setIsDeleteModalOpen(false)}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Delete Ads</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto", maxHeight: "100px !important", padding: "25px", marginTop: 0 }}>
          {/* <h4>Are You Sure?</h4> */}
          <p>
            Are you sure you want to delete 1 ad? <br />
            You will no longer be able to use this ad in any of your campaigns.
          </p>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}>
          <Button color='red' appearance='primary' onClick={() => confirmDelete()}>
            Delete
          </Button>

          <Button color='green' appearance='primary' onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='AdLibraryParent-content'>
        {hideOptions === false ? (
          <>
            <div>
              <AdlibraryCardHeader
                setOpen={setOpen}
                open={open}
                formRef={formRef}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
                selectedRows={selectedRows}
                searchText={searchText}
                setSearchText={setSearchText}
                action={functionSelected}
                setAction={setFunctionSelected}
                adType={adType}
                adStatus={adStatus}
                setTypeState={setTypeState}
                setStatusState={setStatusState}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <div className='gapBetweenCard'>
          <AdlibraryCardBody
            hideOptions={hideOptions}
            adList={filteredList}
            load={load}
            getAllAds={getAllAds}
            handleRowNameSelected={handleRowNameSelected}
            handleRowSelected={handleRowSelected}
            setFunctionSelected={setFunctionSelected}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            totalRows={totalRows}
            pageSize={pageSize}
            pixelStatus={pixelStatus}
          />
        </div>
        <PreviewAdModal openPreviewAd={openPreviewAd} handleClose={handleClose} selectedRows={selectedRows} />
      </div>
    </div>
  )
}

export default Adlibrary
