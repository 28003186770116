import { ViewRoutes } from "../view-routes"

export const RoleViewMapperConfig = [
  {
    name: "contact_menu_access",
    path: "",
    navVisibility: true,
    title: "Contacts",
    component: null
  },
  {
    parent: "contact_menu_access",
    name: "contact_access",
    title: "Contact",
    path: [ViewRoutes.Contact],
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "contact_menu_access",
    name: "company_access",
    title: "Companies",
    path: ViewRoutes.Company,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "contact_menu_access",
    name: "contact_group_access",
    title: "Contact Segment",
    navVisibility: true,
    path: ViewRoutes.ContactSegment,
    component: null,
    hyperlink: true
  },
  {
    name: "conversation_menu_access",
    path: "",
    title: "Conversations",
    navVisibility: true,
    component: null
  },
  {
    parent: "conversation_menu_access",
    name: "snippet_access",
    title: "Snippets",
    path: ViewRoutes.Snippets,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "conversation_menu_access",
    name: "inbox_access",
    title: "Inbox",
    path: ViewRoutes.Inbox,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    title: "Sales",
    name: "sales_menu_access",
    path: "",
    navVisibility: true,
    component: null
  },
  {
    parent: "sales_menu_access",
    title: "Tasks",
    name: "task_access",
    path: ViewRoutes.Tasks,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Deals",
    name: "conversion_access",
    path: ViewRoutes.Conversion,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Tickets",
    name: "ticket_access",
    path: ViewRoutes.Tickets,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Form",
    name: "form_access",
    path: ViewRoutes.From,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Meetings",
    name: "meeting_access",
    path: ViewRoutes.Meetings,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Product",
    name: "product_and_service_access",
    path: ViewRoutes.Products,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "Attendance",
    name: "product_and_service_access",
    path: ViewRoutes.Attendance,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    name: "marketing_menu_access",
    title: "Marketing",
    path: "",
    navVisibility: true,
    component: null
  },
  {
    parent: "marketing_menu_access",
    title: "Campaign",
    name: "campaign_access",
    path: ViewRoutes.Campaign,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "marketing_menu_access",
    title: "Ads",
    name: "web_ads_access",
    path: ViewRoutes.Ads,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    title: "Settings",
    navVisibility: false,
    name: "setting_access",
    path: ViewRoutes.Settings,
    component: null,
    hyperlink: true
  },
  {
    name: "chat_flow_setting_access",
    path: "",
    navVisibility: false,
    title: "Chat Flow Settings",
    component: null
  },
  {
    name: "account_access",
    path: ViewRoutes.Account,
    navVisibility: false,
    title: "All Account",
    component: null,
    hyperlink: true
  },
  {
    title: "Analytics",
    name: "analytics_menu_access",
    path: "",
    navVisibility: true,
    component: null
  },
  {
    parent: "analytics_menu_access",
    title: "Dashboard",
    name: "dashboard_access",
    path: ViewRoutes.Dashboard,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "analytics_menu_access",
    title: "Reports",
    name: "report_access",
    path: ViewRoutes.Report,
    navVisibility: true,
    component: null,
    hyperlink: true
  },
  {
    parent: "sales_menu_access",
    title: "KPI",
    name: "contact_access",
    path: ViewRoutes.Kpi,
    navVisibility: true,
    component: null,
    hyperlink: true
  }
]
