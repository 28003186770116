import { useEffect } from "react"
import DataTable from "react-data-table-component"
import "./adlibraryCardBody.scss"
import { Button } from "rsuite"
import LoadingComponent from "src/common/components/loading/Loading"
import dotSvg from "../../assets/svgs/dot.svg"
import dotOrangeSvg from "../../assets/svgs/dotOrange.svg"
import dotBlueSvg from "../../assets/svgs/dotBlue.svg"
import notFoundSvg from "../../assets/svgs/notFound.svg"
import { stringToSubstringSpreed } from "src/utils"
const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderRadius: "0px",
      backgroundColor: "white"
    }
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  }
}

const AdlibraryCardBody = (props: any) => {
  const {
    handleRowSelected,
    getAllAds,
    load,
    adList,
    pageSize,
    setFunctionSelected,
    hideOptions,
    handleRowNameSelected,
    totalRows,
    handlePerPageChange,
    handlePageChange,
    pixelStatus
  } = props

  const columnsForAdList = [
    {
      name: "Name",
      sortable: true,
      selector: (row: any) => {
        return (
          <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
            <div
              className='imgInTable'
              onClick={() => {
                handleRowNameSelected(row)
              }}
            >
              <img src={row.image_url} style={{ maxHeight: "140px", maxWidth: "100%", width: "80px", cursor:'pointer' }} alt='assets' />
            </div>

            <div
              className='nameProperty'
              onClick={() => {
                handleRowNameSelected(row)
              }}
              title={row?.name}
            >
              {/* {row.name} */}
              {stringToSubstringSpreed(row.name, 24)}
            </div>

            {/* <div style={{ fontSize: "10px" }} className='text-muted'>
              {String(row?.type).toLocaleUpperCase()} | size: {row?.width} * {row?.height}
            </div> */}
          </div>
        )
      },
      width: "350px"
    },
    {
      name: "Type",
      sortable: true,
      selector: (row: any) => {
        return (
          <div className='statusText'>
            &nbsp; {String(row?.type).toLocaleUpperCase()} | size: {row?.width} * {row?.height}
          </div>
        )
      },
      width: "250px"
    },
    {
      name: "Ad Status",
      sortable: true,
      selector: (row: any) => {
        return (
          <div className='statusText'>
            {row.status === "approved" && (
              <>
                <img src={dotSvg} alt='review svg' /> &nbsp;{row.status}
              </>
            )}
            {row.status === "created" && (
              <>
                <div className='flexTable'>
                  {" "}
                  <img src={dotOrangeSvg} alt='pending svg' /> <div className='statusText'>{row.status}</div>{" "}
                </div>
              </>
            )}
            {row.status === "admin_review" && (
              <>
                <img src={dotBlueSvg} alt='notReview svg' /> &nbsp;{row.status}
              </>
            )}
            {!["approved", "created", "admin_review"].includes(row.status) && (
              <>
                <img src={notFoundSvg} alt='not found svg' /> &nbsp;not found
              </>
            )}
          </div>
        )
      },
      width: "150px"
    },
    {
      name: "Landing Page",
      sortable: true,
      selector: (row: any) => {
        return <div className='statusText'>&nbsp;{row.landingPage ?? "not found"}</div>
      },
      width: "150px"
    },
    {
      name: "Created",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.created_date)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div className='statusText'>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "150px"
    },
    {
      name: "Updated",
      sortable: true,
      selector: (row: any) => {
        const updatedDate = new Date(row.updated_date)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }
        const formattedUpdatedDate = updatedDate.toLocaleDateString("en-US", options)

        return <div className='statusText'>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "150px"
    }
  ]

  useEffect(() => {
    getAllAds(1, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  return (
    <>
      <div className={`tableWidth adsTable ${pixelStatus !== null ? "minimumTable-width" : "maximumTable-width"}`}>
        <DataTable
          columns={columnsForAdList as any}
          onSelectedRowsChange={handleRowSelected}
          selectableRows={hideOptions ? true : true}
          selectableRowsSingle
          data={adList}
          progressPending={load}
          progressComponent={<LoadingComponent />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          customStyles={customStyles}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
        />
      </div>
    </>
  )
}

export default AdlibraryCardBody
