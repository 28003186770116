import { Panel } from "rsuite"
import flagIcon from "../../../assets/svgs/flagIcon.svg"
import circleIcon from "../../../assets/svgs/cricleBackground.svg"
import threeDot from "../../../assets/svgs/threeDotHorizon.svg"

type InstantArticleType = {
  file: any
  description: string
  title: string
  calltoAction: string
}

const InstantArticle = (props: InstantArticleType) => {
  const { file, description, title, calltoAction } = props
  return (
    <>
      <div className='panelFormInstantArticle'>
        <div>
          <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
        </div>

        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
          <div className='ar-ad-manager-fb-preview-placeholder'>
            <img
              src={flagIcon}
              alt='flagIcon'
              style={{
                alignItems: "center"
              }}
            />
          </div>

          <div style={{ flex: "0.9" }}>
            <p className='fbTitle'>Roboket</p>
          </div>

          <div className='sponsor' style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
            <p className='sponsored'>Sponsored</p> <img src={threeDot} alt='three dot' />{" "}
          </div>
        </div>

        <div className='bottomDesc'>
          <p> {title.length === 0 ? "Your Title" : title}</p>
          <div style={{overflow:'hidden'}}>{description.length === 0 ? <>This is your Description</> : <>{description}</>}</div>
          <p>
            {calltoAction === "NO_BUTTON" && <></>}
            {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
            {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
            {calltoAction === "DOWNLOAD" && <span>Download</span>}
            {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
            {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
            {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
            {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
            {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
            {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
            {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
            {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
            {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
            {">"}{" "}
          </p>
        </div>
        <hr />
      </div>
    </>
  )
}

export default InstantArticle
