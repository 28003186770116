import { useEffect, useState } from "react"
import { getCampaignStatsForDashboard, getCampaignTableStatsForDashboard } from "src/micro/channel/api-channel"
import TotalImpressionCard from "../TotalImpressionCard/TotalImpressionCard"
import DataTable from "react-data-table-component"
import { DateRangePicker, Icon, Input, InputGroup, Loader } from "rsuite"
import "./adsManagerDashboard.scss"
import { formatDate, formatedStringDate, getLastNDaysDate, getTodayDate } from "src/common/utils/Utils"
import { Link } from "react-router-dom"
import WebsiteAudienceMicroCard from "src/pages/Websites/WebsiteSiteAudience/WebsiteAudienceMicroCard"
import TotalInteractionsCard from "../TotalInteractionsCard/TotalInteractions"
import CommonDateRangePicker from "src/common/commonDateRangePicker/common-dateRangePicker"
import { stringToSubstringSpreed } from "src/utils"

type ICardStat = {
  total_cost?: number
  total_click?: number
  total_view?: number
  total_click_view?: number
  total_view_revenue?: number
  total_click_revenue?: number
  total_impressions?: number
  click_throughs_rate?: number
  cost_per_mile?: number
  cost_per_click?: number
}
const { afterToday } = DateRangePicker
const initialState = {
  fromDateMetrics: getLastNDaysDate(30),
  toDateMetrics: getTodayDate(),
  fromDateImps: getLastNDaysDate(30),
  toDateImps: getTodayDate(),
  fromDateInts: getLastNDaysDate(30),
  toDateInts: getTodayDate(),
  fromDateSttc: getLastNDaysDate(30),
  toDateSttc: getTodayDate()
}
const AdsManagerDashboard = () => {
  const [isFetchingCardInfo, setIsFetchingCardInfo] = useState(false)
  const [isFetchingTableInfo, setIsFetchingTableInfo] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [cardStats, setCardStats] = useState<ICardStat>({})
  const [tableStats, setTableStats] = useState<any>([])
  const [filteredData, setFilteredData] = useState<any>([])
  const [dateFilterRange, setDateFilterRange] = useState<any>(initialState)
  const [allowDateRange, setAllowDateRange] = useState(false)
  const handleDateRange = (dateRange: any, selectedRange: any) => {
    if (selectedRange === "Metrics") {
      const formattedFromDate = formatDate(dateRange[0])
      const formattedToDate = formatDate(dateRange[1])
      setDateFilterRange({
        ...dateFilterRange,
        fromDateMetrics: formattedFromDate,
        toDateMetrics: formattedToDate
      })
    } else if (selectedRange === "Impressions") {
      const formattedFromDate = formatDate(dateRange[0])
      const formattedToDate = formatDate(dateRange[1])
      setDateFilterRange({
        ...dateFilterRange,
        fromDateImps: formattedFromDate,
        toDateImps: formattedToDate
      })
    } else if (selectedRange === "Interactions") {
      const formattedFromDate = formatDate(dateRange[0])
      const formattedToDate = formatDate(dateRange[1])
      setDateFilterRange({
        ...dateFilterRange,
        fromDateInts: formattedFromDate,
        toDateInts: formattedToDate
      })
    } else if (selectedRange === "Statistics") {
      const formattedFromDate = formatDate(dateRange[0])
      const formattedToDate = formatDate(dateRange[1])
      setDateFilterRange({
        ...dateFilterRange,
        fromDateSttc: formattedFromDate,
        toDateSttc: formattedToDate
      })
    }
  }

  const getDashBoardData = async (fromDate: string, toDate: string) => {
    try {
      setIsFetchingCardInfo(true)
      const data = await getCampaignStatsForDashboard("card", fromDate, toDate)
      if (data && Object.hasOwn(data, "hasError")) {
        setCardStats({})
      } else {
        setCardStats({ ...data })
      }
      setIsFetchingCardInfo(false)
    } catch (error) {
      setIsFetchingCardInfo(false)
      setCardStats({})
    }
  }

  const getDashboardTableData = async (fromDate: string, toDate: string) => {
    try {
      setIsFetchingTableInfo(true)
      const data = await getCampaignTableStatsForDashboard(fromDate, toDate)
      if (data && Object.hasOwn(data, "hasError")) {
        setTableStats([])
      } else {
        setTableStats(data)
      }
      setIsFetchingTableInfo(false)
    } catch (error) {
      setIsFetchingTableInfo(false)
      setTableStats([])
    }
  }

  const columnsForDeletedDashboardList = [
    {
      name: "Campaign Name",
      selector: "campaign_name",
      width: "350px",
      sortable: false,
      cell: (row: any) => (
        <div className='ml-1 nameProperty' title={row?.label ?? ""}>
          {row.label ? stringToSubstringSpreed(row.label, 35) : "-"}
        </div>
      )
    },
    {
      name: "Click Revenue",
      selector: "total_clickRevenue",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>{row?.total_clickRevenue != null ? row.total_clickRevenue.toLocaleString() : 0}</div>
    },
    {
      name: "Total Click Through",
      selector: "total_clickThroughs",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>{row?.total_clickThroughs != null ? row.total_clickThroughs.toLocaleString() : 0}</div>
    },
    {
      name: "Total Clicks",
      selector: "total_clicks",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>{row?.total_clicks != null ? row.total_clicks.toLocaleString() : 0}</div>
    },
    {
      name: "Total Cost",
      selector: "total_cost",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>${row.total_cost != null ? row.total_cost.toFixed(2) : "-"}</div>
    },
    {
      name: "Total Impressions",
      selector: "total_impressions",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>{row?.total_impressions != null ? row.total_impressions.toLocaleString() : 0}</div>
    },

    {
      name: "Total View Revenue",
      selector: "total_viewRevenue",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>${row.total_viewRevenue ?? 0}</div>
    },
    {
      name: "Total View Through",
      selector: "total_viewThroughs",
      sortable: false,
      cell: (row: any) => <div className='ml-1'>{row.total_viewThroughs ?? 0}</div>
    }
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        borderRadius: "0px",
        backgroundColor: "white"
      }
    },
    headCells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "12px"
      }
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  }

  useEffect(() => {
    getDashBoardData(dateFilterRange?.fromDateMetrics, dateFilterRange?.toDateMetrics)
  }, [dateFilterRange?.fromDateMetrics, dateFilterRange?.toDateMetrics])

  useEffect(() => {
    getDashboardTableData(dateFilterRange?.fromDateSttc, dateFilterRange?.toDateSttc)
  }, [dateFilterRange?.fromDateSttc, dateFilterRange?.toDateSttc])

  useEffect(() => {
    setFilteredData(tableStats?.filter((item: any) => item?.label?.toLowerCase().includes(searchText?.toLowerCase())))
  }, [searchText])

  return (
    <div className='top-left'>
      <div className='ads-dashboard-container'>
        {/* This will be a separate component */}
        {/* -- start here -- */}
        <div className='ads-metrics'>
          <div className='ads-matrics-headerContent'>
            <div className='ads-metrics-title ads-matrics-left'>
              <h4 className='adtext'>Ads Metrics</h4>
              <p className='subAdtext'>
                *Showing data for {formatedStringDate(dateFilterRange?.fromDateMetrics)} ~{" "}
                {formatedStringDate(dateFilterRange?.toDateMetrics)}
              </p>
            </div>
            <div className='ads-matrics-right'>
              <button onClick={() => setAllowDateRange(!allowDateRange)} className='transparent-button-new'>
                <svg
                  style={{ marginRight: "10px" }}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 12 12'
                  fill='none'
                >
                  <path
                    d='M1 2.3C1 2.01997 1 1.87996 1.0545 1.773C1.10243 1.67892 1.17892 1.60243 1.273 1.5545C1.37996 1.5 1.51997 1.5 1.8 1.5H10.2C10.48 1.5 10.62 1.5 10.727 1.5545C10.8211 1.60243 10.8976 1.67892 10.9455 1.773C11 1.87996 11 2.01997 11 2.3V2.6347C11 2.7691 11 2.83629 10.9836 2.89878C10.969 2.95415 10.9451 3.00662 10.9128 3.05388C10.8763 3.10722 10.8255 3.15123 10.7239 3.23925L7.52606 6.01075C7.42449 6.09877 7.37371 6.14278 7.33725 6.19612C7.30493 6.24338 7.28098 6.29585 7.26642 6.35122C7.25 6.41371 7.25 6.4809 7.25 6.6153V9.22919C7.25 9.32697 7.25 9.37586 7.23423 9.41813C7.22029 9.45548 7.19763 9.48895 7.16813 9.51576C7.13474 9.54611 7.08934 9.56426 6.99856 9.60058L5.29856 10.2806C5.11478 10.3541 5.0229 10.3908 4.94914 10.3755C4.88463 10.3621 4.82803 10.3238 4.79163 10.2689C4.75 10.2061 4.75 10.1071 4.75 9.90919V6.6153C4.75 6.4809 4.75 6.41371 4.73358 6.35122C4.71902 6.29585 4.69507 6.24338 4.66275 6.19612C4.62629 6.14278 4.57551 6.09877 4.47394 6.01075L1.27606 3.23925C1.17449 3.15123 1.12371 3.10722 1.08725 3.05388C1.05493 3.00662 1.03098 2.95415 1.01642 2.89878C1 2.83629 1 2.7691 1 2.6347V2.3Z'
                    stroke='#1D3D70'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
                Filter
              </button>
              {allowDateRange && (
                <CommonDateRangePicker
                  allowDateRange={allowDateRange}
                  fromDate={dateFilterRange.fromDateMetrics}
                  toDate={dateFilterRange.toDateMetrics}
                  handleDateRange={handleDateRange}
                  additionalParameter='Metrics'
                />
              )}
            </div>
          </div>

          <div className='ads-metrics-container'>
            <WebsiteAudienceMicroCard
              height={"108px"}
              width={"auto"}
              minWidth={"175px"}
              headerName={"Total Click"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  (cardStats?.total_click ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      0
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>Clicks</h5> <h6>The number of times an ad was clicked.</h6>
                </div>
              }
            />

            <WebsiteAudienceMicroCard
              height={"108px"}
              width={"auto"}
              minWidth={"175px"}
              headerName={"Total Impression"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  (cardStats?.total_impressions ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      0
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>Impressions</h5> <h6>Amount spent on ads.</h6>
                </div>
              }
            />

            <WebsiteAudienceMicroCard
              height={"108px"}
              width={"auto"}
              minWidth={"175px"}
              headerName={"Total Spend"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  ("$" + cardStats?.total_cost?.toFixed(2) ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      0
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>Spend</h5> <h6>Amount spent on ads.</h6>
                  {/* <p>Clicks / Impression</p> */}
                </div>
              }
            />

            <WebsiteAudienceMicroCard
              width={"auto"}
              height={"108px"}
              minWidth={"175px"}
              headerName={"CTR"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  (cardStats?.click_throughs_rate ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      0
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>CTR</h5> <h6>Click through rate: The percentage of ad serves that were clicked.</h6>
                  <p>Clicks / Impressions</p>
                </div>
              }
            />

            <WebsiteAudienceMicroCard
              height={"108px"}
              minWidth={"175px"}
              width={"auto"}
              headerName={"CPM"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  ("$" + cardStats?.cost_per_mile ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      unavailable
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>CPM</h5> <h6>Cost per mile: Cost per one thousand ad serves.</h6>
                  <p>1000 x Spend / Impression</p>
                </div>
              }
            />

            <WebsiteAudienceMicroCard
              height={"108px"}
              minWidth={"175px"}
              width={"auto"}
              headerName={"CPC"}
              audienceData={
                isFetchingCardInfo ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>
                ) : (
                  ("$" + cardStats?.cost_per_click ?? (
                    <span style={{ fontSize: "12px" }} className='text-muted'>
                      unavailable
                    </span>
                  ))
                )
              }
              audienceWhisperData={
                <div>
                  <h5>CPC</h5> <h6>Cost per click: Your average spend for one click.</h6>
                  <p>Spend / Clicks</p>
                </div>
              }
            />
          </div>
        </div>
        {/* -- ends -- here */}

        <div className='ads-metrics-title ads-matrics-left'>
          <h4 className='adtext'>Website Impressions</h4>
        </div>

        <div className='webImpression'>
          <div style={{ width: "50%" }}>
            <TotalImpressionCard
              type={"impression"}
              title={"Ads Impressions"}
              dateFilterRange={dateFilterRange}
              setDateFilterRange={setDateFilterRange}
              handleDateRange={handleDateRange}
            />
          </div>

          <div style={{ width: "50%" }}>
            <TotalInteractionsCard
              type={"click"}
              title={"Ads Interactions"}
              dateFilterRange={dateFilterRange}
              setDateFilterRange={setDateFilterRange}
              handleDateRange={handleDateRange}
            />
          </div>
        </div>

        <div className='ads-matrics-headerContent'>
          <div className='ads-metrics-title ads-matrics-left'>
            <h4 className='adtext'>Ad Campaigns Statistics</h4>
            <p className='subAdtext'>
              *Showing data for {formatedStringDate(dateFilterRange?.fromDateSttc)} ~ {formatedStringDate(dateFilterRange?.toDateSttc)}
            </p>
          </div>
          <div className='ads-matrics-right'>
            <button onClick={() => setAllowDateRange(!allowDateRange)} className='transparent-button-new'>
              <svg
                style={{ marginRight: "10px" }}
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
              >
                <path
                  d='M1 2.3C1 2.01997 1 1.87996 1.0545 1.773C1.10243 1.67892 1.17892 1.60243 1.273 1.5545C1.37996 1.5 1.51997 1.5 1.8 1.5H10.2C10.48 1.5 10.62 1.5 10.727 1.5545C10.8211 1.60243 10.8976 1.67892 10.9455 1.773C11 1.87996 11 2.01997 11 2.3V2.6347C11 2.7691 11 2.83629 10.9836 2.89878C10.969 2.95415 10.9451 3.00662 10.9128 3.05388C10.8763 3.10722 10.8255 3.15123 10.7239 3.23925L7.52606 6.01075C7.42449 6.09877 7.37371 6.14278 7.33725 6.19612C7.30493 6.24338 7.28098 6.29585 7.26642 6.35122C7.25 6.41371 7.25 6.4809 7.25 6.6153V9.22919C7.25 9.32697 7.25 9.37586 7.23423 9.41813C7.22029 9.45548 7.19763 9.48895 7.16813 9.51576C7.13474 9.54611 7.08934 9.56426 6.99856 9.60058L5.29856 10.2806C5.11478 10.3541 5.0229 10.3908 4.94914 10.3755C4.88463 10.3621 4.82803 10.3238 4.79163 10.2689C4.75 10.2061 4.75 10.1071 4.75 9.90919V6.6153C4.75 6.4809 4.75 6.41371 4.73358 6.35122C4.71902 6.29585 4.69507 6.24338 4.66275 6.19612C4.62629 6.14278 4.57551 6.09877 4.47394 6.01075L1.27606 3.23925C1.17449 3.15123 1.12371 3.10722 1.08725 3.05388C1.05493 3.00662 1.03098 2.95415 1.01642 2.89878C1 2.83629 1 2.7691 1 2.6347V2.3Z'
                  stroke='#1D3D70'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
              Filter
            </button>
            {allowDateRange && (
              <CommonDateRangePicker
                allowDateRange={allowDateRange}
                fromDate={dateFilterRange.fromDateSttc}
                toDate={dateFilterRange.toDateSttc}
                handleDateRange={handleDateRange}
                additionalParameter='Statistics'
              />
            )}
          </div>
        </div>

        <div className='rounded table-responsive'>
          {isFetchingTableInfo ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "120px", flexDirection: "column" }}>
              <Loader />
              <div className='text-muted'>Please wait</div>
            </div>
          ) : (
            <>
              <div className='website_audienceHeaderCard'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div className='searchPad'>
                    <InputGroup style={{ width: 214 }}>
                      <Input placeholder='Search' value={searchText} onChange={(value) => setSearchText(value)} />
                      <InputGroup.Button>
                        <Icon icon='search' />
                      </InputGroup.Button>
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div className='table'>
                <DataTable
                  columns={columnsForDeletedDashboardList as any}
                  data={searchText?.length > 0 ? filteredData : tableStats}
                  progressPending={isFetchingTableInfo}
                  persistTableHead
                  pagination={true}
                  customStyles={customStyles}
                  paginationPerPage={5}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdsManagerDashboard
