import { useEffect, useState } from "react"
import "./campaignList.scss"
import { postProviderAd } from "./api-campaignList"
import { Alert, Button, Col, DateRangePicker, Dropdown, Icon, Input, InputGroup, Row, SelectPicker } from "rsuite"
import dotSvg from "../../assets/svgs/dot.svg"
import grayDotSvg from "../../assets/svgs/graySvg.svg"
import pause from "../../assets/svgs/pause.svg"
import DataTable from "react-data-table-component"
import LoadingComponent from "src/common/components/loading/Loading"
import { useProviderId } from "src/common/constants"
import { Link, useHistory } from "react-router-dom"
import { formatDate, formatedStringDate, getLastNDaysDate, getTodayDate } from "src/common/utils/Utils"
import CommonErrorMessage from "src/common/commonErrorMessage"
import CommonDateRangePicker from "src/common/commonDateRangePicker/common-dateRangePicker"
import { stringToSubstringSpreed } from "src/utils"

// import CommonDateRangePicker from "src/common/commonDateRangePicker/common-dateRangePicker"

const { afterToday } = DateRangePicker
const initialState = {
  formDate: getLastNDaysDate(30),
  toDate: getTodayDate()
}

// export default Ranges
const CampaignList = (props: any) => {
  const { pixelStatus } = props
  const [listData, setListData] = useState([])
  const [load, setLoad] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [allowDateRange, setAllowDateRange] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filteredList, setFilteredList] = useState<any>([])
  const [statusState, setStatusState] = useState("")
  const [dateFilterRange, setDateFilterRange] = useState<any>(initialState)

  // const pagination = false

  const getAllCampaign = async (fromDate: string, toDate: string) => {
    const providerId = await useProviderId()
    try {
      setLoad(true)
      const result = await postProviderAd(providerId, fromDate, toDate)
      setListData(result.data)
      setFilteredList(result.data)
      setErrorMessage(false)
    } catch (error) {
      // Alert.error("Error")
      setErrorMessage(true)
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  const history = useHistory()

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        borderRadius: "0px",
        backgroundColor: "white"
      }
    },
    headCells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "12px"
      }
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  }

  const handleRowSelected = (row: any) => {
    if (row?.channel === "social") {
      history.push(`/ads/update-facebook-campaign?eid=${row.eid}`)
    } else {
      history.push(`/ads/update-campaign?eid=${row.eid}`)
    }
  }

  const columnsForAdList = [
    {
      name: "Name",
      sortable: true,
      selector: (row: any) => {
        return (
          <div className='nameProperty'>
            <p title={row.name}>
              {" "}
              <Link to={`/ads/campaign/${row.eid}`}>{row.name ? stringToSubstringSpreed(row.name, 35) : "-"}</Link>{" "}
            </p>
          </div>
        )
      },
      width: "320px"
    },
    {
      name: "Channel",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.channel ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "KPI Metrics",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.kpiMetric ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "KPI Goal",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.kpiGoal ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "impressions",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.impressions ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "Funnel Stage",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.funnelStage ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "Status",
      sortable: true,
      selector: (row: any) => {
        return (
          <div className='statusText'>
            {row.hasOwnProperty("is_draft") ? (
              <>
                {" "}
                <img src={grayDotSvg} alt='gray svg' /> &nbsp; Draft
              </>
            ) : (
              <>
                {row.status === "live" ? (
                  <>
                    {/* <img src={dotSvg} alt='review svg' /> &nbsp; Running */}
                    <img src={dotSvg} alt='review svg' /> &nbsp; Running
                  </>
                ) : (
                  <>
                    <p
                      title={
                        row.actualStatusString === "billingFailedPermanent"
                          ? "Billing Failed Permanent"
                          : row.actualStatusString === "notInReview"
                            ? "Not In Review"
                            : row.actualStatusString ?? "-"
                      }
                    >
                      {row.actualStatusString === "billingFailedPermanent"
                        ? "Billing Failed Permanent"
                        : row.actualStatusString === "notInReview"
                          ? "Not In Review"
                          : row.actualStatusString ?? "-"}{" "}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        )
      },
      width: "200px"
    },
    {
      name: "Budget",
      sortable: true,
      selector: (row: any) => {
        return (
          <div className='statusText'>
            &nbsp;{"$" + row.budgetGoal} {row.budgetType}
          </div>
        )
      },
      width: "150px"
    },
    {
      name: "Created at",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.createdDate)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div className='statusText'>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "180px"
    },
    {
      name: "Spend",
      sortable: true,
      selector: (row: any) => {
        return <div className='statusText'>&nbsp;{row.spend === null ? <>$0.00</> : <>${Number(row.spend).toFixed(2)}</>}</div>
      },
      width: "150px"
    },
    {
      name: "Frequency",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.frequency ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "Clicks",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.clicks ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "CPC",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.cpc ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "CPM",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.cpm ?? "-"}</div>
      },
      width: "150px"
    },
    {
      name: "Bounce Rate",
      sortable: true,
      selector: (row: any) => {
        return <div className=''>&nbsp;{row.bounceRate ?? "-"}</div>
      },
      width: "150px"
    },

    {
      name: "Start date",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.startDate)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div className='statusText'>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "180px"
    },
    {
      name: "End date",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.endDate)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div className='statusText'>&nbsp;{formattedUpdatedDate === "Jan 1, 1970" ? <>-</> : <> {formattedUpdatedDate} </>}</div>
      },
      width: "180px"
    },
    {
      name: "Actions",
      sortable: true,
      selector: (row: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button className='editBtn' onClick={() => handleRowSelected(row)}>
              Edit
            </Button>
          </div>
        )
      },
      width: "180px"
    }
  ]

  function statusValue(label: string) {
    switch (label) {
      case "Billing Failed Permanent":
        return "billingFailedPermanent"
      case "Review":
        return "review"
      case "Not In Review":
        return "notInReview"
      default:
        return ""
    }
  }

  const statusData = ["Billing Failed Permanent", "Review", "Not In Review"].map((item) => ({ label: item, value: statusValue(item) }))

  const handleStatusChange = (value: string) => {
    setStatusState(value)
  }
  const handleDateRange = (dateRange: any) => {
    // if (selectedRange === "Metrics") {
    const formattedFromDate = formatDate(dateRange[0])
    const formattedToDate = formatDate(dateRange[1])
    setDateFilterRange({
      ...dateFilterRange,
      formDate: formattedFromDate,
      toDate: formattedToDate
    })
  }

  useEffect(() => {
    getAllCampaign(dateFilterRange?.formDate, dateFilterRange?.toDate)
  }, [dateFilterRange?.formDate, dateFilterRange?.toDate])

  useEffect(() => {
    if (statusState === null) {
      setFilteredList(listData)
    } else {
      const filtered = listData?.filter((data: any) => data?.actualStatusString?.toLowerCase().includes(statusState?.toLowerCase()))
      setFilteredList(filtered)
    }
  }, [statusState])

  useEffect(() => {
    const filtered = listData?.filter((data: any) => data?.name?.toLowerCase().includes(searchText.toLowerCase()))
    setFilteredList(filtered)
  }, [searchText])

  return (
    <div className='AdLibraryParent'>
      {errorMessage && (
        <div style={{ marginTop: "15px" }}>
          {" "}
          <CommonErrorMessage
            msgType={"warning"}
            description={"Fetching limit has exceeded. Please wait or contact with your administrator."}
          />
        </div>
      )}
      <div className='adsCampaign-header'>
        <div className='adsCampaign-header-left'>
          {" "}
          <p className='adtext'>Ads Campaign List</p>
          <p className='subAdtext'>
            {" "}
            *Showing data for {formatedStringDate(dateFilterRange?.formDate)} ~ {formatedStringDate(dateFilterRange?.toDate)}
          </p>
        </div>
        <div className='adsCampaign-header-right'>
          {" "}
          <button onClick={() => setAllowDateRange(!allowDateRange)} className='transparent-button-new'>
            <svg style={{ marginRight: "10px" }} xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <path
                d='M1 2.3C1 2.01997 1 1.87996 1.0545 1.773C1.10243 1.67892 1.17892 1.60243 1.273 1.5545C1.37996 1.5 1.51997 1.5 1.8 1.5H10.2C10.48 1.5 10.62 1.5 10.727 1.5545C10.8211 1.60243 10.8976 1.67892 10.9455 1.773C11 1.87996 11 2.01997 11 2.3V2.6347C11 2.7691 11 2.83629 10.9836 2.89878C10.969 2.95415 10.9451 3.00662 10.9128 3.05388C10.8763 3.10722 10.8255 3.15123 10.7239 3.23925L7.52606 6.01075C7.42449 6.09877 7.37371 6.14278 7.33725 6.19612C7.30493 6.24338 7.28098 6.29585 7.26642 6.35122C7.25 6.41371 7.25 6.4809 7.25 6.6153V9.22919C7.25 9.32697 7.25 9.37586 7.23423 9.41813C7.22029 9.45548 7.19763 9.48895 7.16813 9.51576C7.13474 9.54611 7.08934 9.56426 6.99856 9.60058L5.29856 10.2806C5.11478 10.3541 5.0229 10.3908 4.94914 10.3755C4.88463 10.3621 4.82803 10.3238 4.79163 10.2689C4.75 10.2061 4.75 10.1071 4.75 9.90919V6.6153C4.75 6.4809 4.75 6.41371 4.73358 6.35122C4.71902 6.29585 4.69507 6.24338 4.66275 6.19612C4.62629 6.14278 4.57551 6.09877 4.47394 6.01075L1.27606 3.23925C1.17449 3.15123 1.12371 3.10722 1.08725 3.05388C1.05493 3.00662 1.03098 2.95415 1.01642 2.89878C1 2.83629 1 2.7691 1 2.6347V2.3Z'
                stroke='#1D3D70'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
            Filter
          </button>
          {allowDateRange && (
            <CommonDateRangePicker
              allowDateRange={allowDateRange}
              fromDate={dateFilterRange.formDate}
              toDate={dateFilterRange.toDate}
              handleDateRange={handleDateRange}
            />
          )}
        </div>
      </div>

      <div className='AdlibraryHeaderCard'>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <SelectPicker
            data={statusData}
            searchable={false}
            placeholder='All Status'
            onChange={handleStatusChange}
            style={{ width: 224 }}
          />

          <div className='searchPad'>
            <InputGroup style={{ width: 214 }}>
              <Input placeholder='Search' value={searchText} onChange={(value) => setSearchText(value)} />
              <InputGroup.Button>
                <Icon icon='search' />
              </InputGroup.Button>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className={`tableWidth ${pixelStatus !== null ? "minimumTable-height" : "maximumTable-height"}`}>
        <DataTable
          columns={columnsForAdList as any}
          data={filteredList}
          progressPending={load}
          progressComponent={<LoadingComponent />}
          pagination={true}
          persistTableHead
          customStyles={customStyles}
        />
      </div>
    </div>
  )
}

export default CampaignList
