import React, { useState } from "react"
import "./styles/TypeofAdd.scss"
import { Alert, Button, Col, Form, Grid, Input, List, Modal, Panel, Row, Schema, SelectPicker } from "rsuite"
import { web } from "src/assets"
import { monthNames, typedOfAdd } from "./Rules"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import { createUploadAd, displayFbAd } from "./apiUploadAd"
import { convertImageToBase64, convertImageToBase64Ad } from "src/common/utils/Utils"
import { useHistory } from "react-router-dom"
import LoadingComponent from "src/common/components/loading/Loading"

import { Constants, useProviderId } from "src/common/constants"
import CustomField from "../custom-field/custom-field.component"

import threeDot from "../../assets/svgs/threeDotHorizon.svg"
import nextBtn from "../../assets/svgs/nextBtnsvg.svg"
import previousBtn from "../../assets/svgs/previousBtnSvg.svg"
import AudiNetWorkInter from "./Preset/AudiNetWorkInter"
import AudiNetWorkNative from "./Preset/AudiNetWorkNative"
import AudiNetBanner from "./Preset/AudiNetBanner"
import InstaFeed from "./Preset/InstaFeed"
import InstantArticle from "./Preset/InstantArticle"
import InstagramStory from "./Preset/InstagramStory"
import InstagramStoryFeed from "./Preset/InstagramExploreFeed"
import MessengerStory from "./Preset/MessengerStory"
import MarketPlace from "./Preset/MarketPlace"

type TypeofAddProps = {
  file: any
  setFile: React.Dispatch<React.SetStateAction<any>>
  selectedType: any
  setSelectedTypes: React.Dispatch<React.SetStateAction<any>>
  imgUplaod: any
}

interface fbListType {
  eid: string
  name: string
  url: string
}

const viewData = [
  "Desktop News Feed",
  "Desktop Right Column",
  "Mobile News Feed",
  "Desktop Marketplace Feed",
  "Desktop Marketplace Details Page",
  "Mobile Marketplace Feed",
  "General Search Results (Mobile)",
  "Marketplace Search Results (Mobile)",
  "Audience Network Interstitial",
  "Audience Network Native",
  "Audience Network Banner",
  "Instagram Feed",
  "Instagram Stories",
  "Instagram Explore Feed",
  "Instant Articles",
  "Facebook Stories",
  "Messenger Stories",
  "Marketplace Category Feed & Details Page"
].map((item) => ({ label: item, value: item }))

const TypeofAdd: React.FC<TypeofAddProps> = ({ file, setFile, selectedType, setSelectedTypes, imgUplaod }) => {
  const [isChecked, setChecked] = useState("")
  const [chooseType, setChooseType] = useState("")
  const [calltoAction, setCalltoAction] = useState("BOOK_TRAVEL")
  const [fbList, setFbList] = useState<fbListType[]>([])
  const [fbModal, setFbModal] = useState(false)
  const [selectedEid, setSelectedEid] = useState<string | null>(null)

  const today = new Date()

  const history = useHistory()

  const formattedDate = `${today.getFullYear()}${monthNames[today.getMonth()]}${today.getDate().toString().padStart(2, "0")}`
  const formattedFileName = `${formattedDate}-${file[0].name}`
  const [isCreating, setIsCreating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [descriptionUrl, setDescriptionUrl] = useState("")
  const [BrandName, setBrandName] = useState("")
  const [pageName, setPageName] = useState("")
  const [pageUrl, setPageUrl] = useState("")
  const [formError, setFormError] = useState<any>({})
  // const [previewState, setPreviewState] = useState("Desktop News Feed")

  const [previewState, setPreviewState] = useState(viewData[0].value)
  const [currentIndex, setCurrentIndex] = useState(0)

  const [adDetails, setAdDetails] = useState({
    name: formattedFileName ?? "",
    title: "",
    description: "",
    url: "",
    read_only: false,
    status: "created",
    multiple_products: 0,
    is_multi_share_optimized: false,
    type: "",
    subtype: "",
    is_dynamic: false,
    do_not_forward: false,
    ad_parameters: [],
    brandName: "",
    assets: [{}]
  })
  const formRef: any = React.useRef(null)

  const { StringType } = Schema.Types

  const validateDescription = StringType().addRule((value, data) => {
    if (!value) return true
    const words = value.split(" ")
    for (let word of words) {
      if (word.length > 14) {
        return false
      }
    }
    return true
  }, "No word should be longer than 14 letters.")

  const modelWeb = Schema.Model({
    name: StringType().isRequired("This field is required."),
    descriptionUrlWeb: StringType().isRequired("This field is required.")
  })

  const modelWebNative = Schema.Model({
    name: StringType().isRequired("This field is required."),
    title: StringType().isRequired("This field is required."),
    description: StringType().isRequired("This field is required."),
    descriptionUrl: StringType().isRequired("This field is required."),
    BrandName: StringType().isRequired("This field is required.")
  })

  const modelFacebook = Schema.Model({
    name: StringType().isRequired("This field is required."),
    title: StringType().isRequired("This field is required."),
    description: validateDescription.isRequired("This field is required."),
    descriptionUrl: StringType().isRequired("This field is required.")
  })

  const handleChange = (event: string) => {
    setName(event)
  }

  const handleTitleChange = (event: string) => {
    setTitle(event)
  }

  const handleRadioChange = (eid: string, name: string, url: string) => {
    setSelectedEid(eid)
    setPageName(name)
    setPageUrl(url)
  }

  const handleDescriptionChange = (event: string) => {
    setDescription(event)
  }

  const handleBrandNameChange = (event: string) => {
    setBrandName(event)
  }

  const handleCloseList = () => {
    setFbModal(false)
  }

  const handleUrlChange = (event: string) => {
    // if (event.includes('.com') && event.split('.com')[1] !== '') {
    //   return;
    // }

    setDescriptionUrl(event)
  }

  const handleCheckboxChange = (id: string) => {
    setChecked(id)
  }

  const handlePreviewChange = (value: any) => {
    setPreviewState(value)
    const newIndex = viewData.findIndex((item) => item.value === value)
    setCurrentIndex(newIndex)
  }

  const handleNext = () => {
    if (currentIndex < viewData.length - 1) {
      const nextIndex = currentIndex + 1
      setPreviewState(viewData[nextIndex].value)
      setCurrentIndex(nextIndex)
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1
      setPreviewState(viewData[prevIndex].value)
      setCurrentIndex(prevIndex)
    }
  }

  const showFbPage = async () => {
    setFbModal(true)
    setLoading(true)
    try {
      const result = await displayFbAd()
      setFbList(result)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectChange = (value: string) => {
    setCalltoAction(value)
  }

  const handleCreateAdd = async () => {
    const base64Image = (await convertImageToBase64Ad(file[0])) as string

    if (!formRef.current.check()) {
      Alert.error("form invalid", Constants.Error_Alert_Constant_Time)
      return
    }
    setIsCreating(true)
    let payloads

    if (isChecked === "NativeAds") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "native",
        subtype: "NATIVE_AD_600x600",
        assets: [
          {
            name: "Native ad destination URL",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Native ad image",
            type: "image",
            subtype: "ad",
            file: base64Image
          },
          {
            name: "Native ad title",
            type: "text",
            subtype: "title",
            value: title
          },
          {
            name: "Native ad description",
            type: "text",
            subtype: "message",
            value: description
          },
          {
            name: "Native ad brand name",
            type: "text",
            subtype: "brand_name",
            value: BrandName
          }
        ]
      }
    } else if (isChecked === "FacebookInstagramImage") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "facebook",
        subtype: "LEADERBOARD",
        assets: [
          {
            name: "Asset url",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Asset title",
            type: "text",
            subtype: "title",
            value: title
          },
          {
            name: "Asset description",
            type: "text",
            subtype: "message",
            value: description
          },
          {
            name: "Asset action",
            type: "text",
            subtype: "call_to_action",
            value: calltoAction
          },

          {
            name: "Asset image",
            type: "image",
            subtype: "ad",
            file: base64Image
          },

          {
            name: "Facebook asset 2 ad facebook",
            type: "text",
            subtype: "actor_id",
            value: selectedEid
          }
        ]
      }
    } else if (isChecked === "Web") {
      payloads = {
        name: name,
        title: title,
        description: description,
        url: descriptionUrl,
        read_only: false,
        brandName: BrandName,
        do_not_forward: false,
        ad_parameters: [],
        is_dynamic: false,
        status: "created",
        multiple_products: 0,
        is_multi_share_optimized: false,
        type: "web",
        subtype: "LEADERBOARD",
        assets: [
          {
            name: "Static ad destination URL",
            type: "text",
            subtype: "url",
            value: descriptionUrl
          },
          {
            name: "Static ad image",
            type: "image",
            subtype: "ad",
            file: base64Image
          }
        ]
      }
    }

    try {
      const providerId = await useProviderId()
      const result = await createUploadAd(providerId, payloads)
      Alert.success("Assets Created Successfully")
      localStorage.setItem("selectedMenuItem", "ad-library")
      setTimeout(() => {
        window.location.href = "/ads"
      }, 2000)
      history.push("/ads")
    } catch (error) {
      Alert.error("Error in API request")
    } finally {
      setIsCreating(false)
    }
  }

  const dataCallAction = [
    "None",
    "Apply Now",
    "Book Now",
    "Contact Us",
    "Download",
    "Get Offer",
    "Learn More",
    "Listen Now",
    "Send Message",
    "Shop Now",
    "Sign Up",
    "Watch More",
    "Buy Now"
  ].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "None":
        return "NO_BUTTON"
      case "Apply Now":
        return "APPLY_NOW"
      case "Book Now":
        return "BOOK_TRAVEL"

      case "Contact Us":
        return "CONTACT_US"

      case "Download":
        return "DOWNLOAD"

      case "Get Offer":
        return "GET_OFFER"

      case "Learn More":
        return "LEARN_MORE"
      case "Listen Now":
        return "LISTEN_MUSIC"

      case "Send Message":
        return "MESSAGE_PAGE"

      case "Shop Now":
        return "SHOP_NOW"

      case "Sign Up":
        return "SIGN_UP"

      case "Watch More":
        return "WATCH_MORE"

      case "Buy Now":
        return "BUY_NOW"
      default:
        return label
    }
  }

  return (
    <>
      {chooseType !== "upload" && (
        <div className='uploadAdd__modal-typeofAdd-area'>
          <div className='uploadAdd__modal-typeofAdd-area-content'>
            <h4>What type of ad do you want to create?</h4>
            <p>We'll generate ads for each of the channels selected.</p>
          </div>

          <div className='uploadAdd__modal-typeofAdd-area-content-middle'>
            <Grid fluid>
              <Row gutter={26} className='show-grid'>
                {typedOfAdd?.map((item: any) => {
                  return (
                    <Col xs={24} md={24} lg={12}>
                      <div
                        className={selectedType.includes(item?.id) ? "activeTypeofAdd_card" : "typeofAdd_card disableTypeofAdd_card"}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCheckboxChange(item?.id)}
                      >
                        <div className='typeofAdd_card_left'>
                          <img src={item?.icon} alt='web' />
                          <div className='typeofAdd_card_left_content'>
                            <h5>{item?.title}</h5>
                            <p>{item?.content}</p>
                          </div>
                        </div>

                        <div className='typeofAdd_card_right'>
                          <div className='round'>
                            <input type='radio' checked={selectedType.includes(item?.id)} id='radio' />
                            {item?.id === isChecked && <label htmlFor='radio'></label>}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Grid>
            <div className='uploadAdd__modal-typeofAdd-footer'>
              <div className='uploadAdd__modal-typeofAdd-footer-content'>
                <button
                  onClick={() => {
                    setFile({})
                    setSelectedTypes("")
                  }}
                >
                  Back
                </button>
                <button
                  disabled={isChecked === ""}
                  onClick={() => setChooseType("upload")}
                  className={isChecked ? "activeFooterButton" : "nonActiveFooterButton"}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {chooseType === "upload" && (
        <div className='uploadAdd__modal-addDetails'>
          <div className='uploadAdd__modal-typeofAdd-area-content-body'>
            <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Ad Details</h4>
            <Grid fluid>
              <Row gutter={16} className='show-grid'>
                <Col xs={24} md={24} lg={12}>
                  <p className='details__page-title-left'>Ad Details Description</p>
                  {isChecked === "FacebookInstagramImage" && <div></div>}
                  <div className='uploadAdd__modal-left'>
                    {isChecked === "Web" && (
                      <Form
                        model={modelWeb}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <label className='rs-control-label labelFont'>Name</label>
                        <CustomField
                          name='name'
                          placeholder='Name'
                          accepter={Input}
                          size='lg'
                          value={name}
                          onChange={handleChange}
                          error={formError.name}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='descriptionUrlWeb'
                          placeholder='https://roboket.com?utm_source=adroll&utm_medium=adrol'
                          accepter={Input}
                          size='lg'
                          value={descriptionUrl}
                          onChange={handleUrlChange}
                          error={formError.descriptionUrl}
                        />
                      </Form>
                    )}

                    {isChecked === "FacebookInstagramImage" && (
                      <Form
                        model={modelFacebook}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Name</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>{name?.length ?? 0}/51</div>
                          <CustomField
                            name='name'
                            placeholder='Name'
                            accepter={Input}
                            size='lg'
                            value={name}
                            onChange={handleChange}
                            error={formError.name}
                            maxLength={51}
                            style={{ paddingRight: "60px" }}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Title</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>{title?.length ?? 0}/255</div>

                          <CustomField
                            name='title'
                            placeholder='Title'
                            accepter={Input}
                            size='lg'
                            value={title}
                            onChange={handleTitleChange}
                            error={formError.title}
                            style={{ paddingRight: "95px" }}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Description</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>
                            {description?.length ?? 0}/1024
                          </div>
                          <CustomField
                            name='description'
                            placeholder='Description'
                            accepter={Input}
                            size='lg'
                            value={description}
                            onChange={handleDescriptionChange}
                            error={formError.description}
                            style={{ paddingRight: "95px" }}
                          />
                        </div>
                        <div className='formLabelAlign'>
                          <label className='rs-control-label labelFont'>Description url</label>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div style={{ position: "absolute", zIndex: "999", right: "10px", top: "10px" }}>
                            {descriptionUrl?.length ?? 0}/947
                          </div>
                          <CustomField
                            name='descriptionUrl'
                            placeholder='https://roboket.com'
                            accepter={Input}
                            size='lg'
                            value={descriptionUrl}
                            onChange={handleUrlChange}
                            error={formError.descriptionUrl}
                            style={{ paddingRight: "95px" }}
                          />
                        </div>
                        <label className='rs-control-label labelFont'>Call to Action</label>
                        <CustomField
                          name='selectedOption'
                          accepter={SelectPicker}
                          data={dataCallAction}
                          size='lg'
                          value={calltoAction}
                          onChange={handleSelectChange}
                          error={formError.selectedOption}
                          searchable={false}
                          cleanable={false}
                        />
                        <label className='rs-control-label labelFont mt-4'>Facebook Identity</label>
                        <br />
                        <Button color='blue' appearance='default' onClick={showFbPage}>
                          Select a page to connect
                        </Button>
                        <br /> <br />
                        {pageName.length > 0 && (
                          <CustomField
                            name='pageDesc'
                            accepter={Input}
                            size='lg'
                            value={pageName + "   " + pageUrl}
                            error={formError.pageDesc}
                            disabled
                          />
                        )}
                      </Form>
                    )}

                    {isChecked === "NativeAds" && (
                      <Form
                        model={modelWebNative}
                        fluid
                        ref={formRef}
                        onCheck={(formError) => {
                          setFormError(formError)
                        }}
                      >
                        <label className='rs-control-label labelFont'>Name</label>
                        <CustomField
                          name='name'
                          placeholder='Name'
                          accepter={Input}
                          size='lg'
                          value={name}
                          onChange={handleChange}
                          error={formError.name}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='title'
                          placeholder='Title'
                          accepter={Input}
                          size='lg'
                          value={title}
                          onChange={handleTitleChange}
                          error={formError.title}
                        />

                        <label className='rs-control-label labelFont'>Description</label>
                        <CustomField
                          name='description'
                          placeholder='Description'
                          accepter={Input}
                          size='lg'
                          value={description}
                          onChange={handleDescriptionChange}
                          error={formError.description}
                        />

                        <label className='rs-control-label labelFont'>Description url</label>
                        <CustomField
                          name='descriptionUrl'
                          placeholder='https://roboket.com?utm_source=adroll&utm_medium=adrol'
                          accepter={Input}
                          size='lg'
                          value={descriptionUrl}
                          onChange={handleUrlChange}
                          error={formError.descriptionUrl}
                        />

                        <label className='rs-control-label labelFont'>Brand Name</label>
                        <CustomField
                          name='BrandName'
                          placeholder='Brand Name'
                          accepter={Input}
                          size='lg'
                          value={BrandName}
                          onChange={handleBrandNameChange}
                          error={formError.BrandName}
                        />
                      </Form>
                    )}
                  </div>
                </Col>

                <Col xs={24} md={24} lg={12}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p className='details__page-title'>Ad Preview</p>

                    <div onClick={() => setFile({})} className='preview_card_right'>
                      Delete
                    </div>
                  </div>
                  {isChecked === "NativeAds" && (
                    <div className='uploadAdd__modal-right panelForm'>
                      <div className='typeofAdd_card detailsTypeofAdd_card'>
                        <div className='typeofAdd_card_left'>
                          <img src={web} alt='web' />
                          <div className='typeofAdd_card_left_content uploadAdd__modal-right-context'>
                            <p>Connect</p>
                            <h5>Web banner</h5>
                          </div>
                        </div>
                      </div>
                      <div className='uploadAdd__modal-right-midContent'>
                        <img src={URL?.createObjectURL(file[0])} className='nativeAdBanner' alt='file_preview' />
                      </div>
                      {selectedType !== "Web" && (
                        <div className='uploadAdd__modal-right-footerContent'>
                          <div className='typeofAdd_card_left'>
                            <img src={web} alt='web' />
                            <div className='typeofAdd_card_left_content uploadAdd__modal-right-context preview-footer-content'>
                              <h5>{adDetails.title?.length > 0 ? adDetails.title : "Select title"}</h5>
                              <p>Roboket</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {isChecked === "FacebookInstagramImage" && (
                    <>
                      <div className='adPreviewSelectPickerAndBtn'>
                        <SelectPicker
                          searchable={false}
                          cleanable={false}
                          value={previewState}
                          data={viewData}
                          style={{ width: 394 }}
                          onChange={handlePreviewChange}
                        />
                        <Button onClick={handlePrevious}>
                          <img src={previousBtn} alt='Previous' />
                        </Button>
                        <Button onClick={handleNext}>
                          <img src={nextBtn} alt='Next' />
                        </Button>
                      </div>

                      {previewState === "Desktop News Feed" && (
                        <Panel shaded bordered bodyFill className='panelForm'>
                          <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                            <div className='ar-ad-manager-fb-preview-placeholder'>
                              <img
                                src={flagIcon}
                                alt='flagIcon'
                                style={{
                                  alignItems: "center"
                                }}
                              />
                            </div>

                            <div>
                              <p className='fbTitle'>Roboket</p>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div>
                                  <p className='sponsorText'>Sponsored</p>
                                </div>
                                <div>
                                  <img src={earthIcon} alt='earth icon' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                            {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                          </div>
                          <Panel>
                            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
                          </Panel>
                          <hr />
                          <Panel>
                            <div style={{ overflow: "hidden" }}>
                              {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <p className='tilePanel' style={{ overflow: "hidden" }}>
                                {title.length === 0 ? "Your Title" : title}
                              </p>

                              {calltoAction === "NO_BUTTON" && <></>}

                              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                            </div>
                          </Panel>

                          <Panel>
                            <div className='likeShareIcon'>
                              <div className='displayed'>
                                <img src={likeIcon} alt='likeIcon' />
                                Like
                              </div>
                              <div className='displayed'>
                                <img src={commentIcon} alt='commentIcon' />
                                Comment
                              </div>
                              <div className='displayed'>
                                <img src={shareIcon} alt='shareIcon' />
                                Share
                              </div>
                            </div>
                          </Panel>
                        </Panel>
                      )}

                      {previewState === "Desktop Right Column" && (
                        <Panel shaded bordered bodyFill className='panelForm'>
                          <div className='adDiffPreview'>
                            <div className='flexDir'>
                              <div style={{ width: "171px" }}>
                                <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
                              </div>

                              <div>
                                <p style={{ fontSize: "14px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                                <p style={{ fontSize: "11px", fontWeight: "300" }}>
                                  {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
                                </p>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </Panel>
                      )}

                      {previewState === "Mobile News Feed" && (
                        <Panel shaded bordered bodyFill className='panelFormMobile'>
                          <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                            <div className='ar-ad-manager-fb-preview-placeholder'>
                              <img
                                src={flagIcon}
                                alt='flagIcon'
                                style={{
                                  alignItems: "center"
                                }}
                              />
                            </div>

                            <div>
                              <p className='fbTitle'>Roboket</p>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div>
                                  <p className='sponsorText'>Sponsored</p>
                                </div>
                                <div>
                                  <img src={earthIcon} alt='earth icon' />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                            {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                          </div>
                          <Panel>
                            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
                          </Panel>
                          <hr />
                          <Panel>
                            <div>{descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                              {calltoAction === "NO_BUTTON" && <></>}

                              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                            </div>
                          </Panel>

                          <Panel>
                            <div className='likeShareIcon'>
                              <div className='displayed'>
                                <img src={likeIcon} alt='likeIcon' />
                                Like
                              </div>
                              <div className='displayed'>
                                <img src={commentIcon} alt='commentIcon' />
                                Comment
                              </div>
                              <div className='displayed'>
                                <img src={shareIcon} alt='shareIcon' />
                                Share
                              </div>
                            </div>
                          </Panel>
                        </Panel>
                      )}

                      {previewState === "Desktop Marketplace Feed" && (
                        <>
                          <div className='adDiffPreview'>
                            <div style={{ width: "171px" }}>
                              <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
                            </div>
                          </div>
                          <div style={{ padding: "0px 20px" }}>
                            <p style={{ fontSize: "12px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                            <p style={{ fontSize: "11px", fontWeight: "300" }}>
                              {descriptionUrl.length === 0 ? "ADNDIGINET.COM" : descriptionUrl.split(".com")[0] + ".com"}
                            </p>
                          </div>
                        </>
                      )}

                      {previewState === "Desktop Marketplace Details Page" && (
                        <>
                          <div className='adDiffPreview'>
                            <div className='iconTool'>
                              <img className='circleAshColor' style={{ width: "51px" }} src={circleIcon} alt='circleAshColor' />
                              <img
                                className='flagIconStyle'
                                src={flagIcon}
                                alt='flagIcon'
                                style={{
                                  position: "absolute",
                                  top: 138,
                                  left: 47,
                                  width: "25px",
                                  height: "25px"
                                }}
                              />
                            </div>

                            <div style={{ width: "271px" }}>
                              <p className='fbTitleDesktop'>Roboket</p>
                              <img
                                style={{ borderRadius: "19px" }}
                                src={URL?.createObjectURL(file[0])}
                                alt='file_preview'
                                className='imgFbAd'
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {previewState === "Mobile Marketplace Feed" && (
                        <Panel shaded bordered bodyFill className='panelFormMobile'>
                          <div style={{ padding: "10px 15px", display: "flex", flex: "1", alignItems: "center" }}>
                            <div className='ar-ad-manager-fb-preview-placeholder'>
                              <img
                                src={flagIcon}
                                alt='flagIcon'
                                style={{
                                  alignItems: "center"
                                }}
                              />
                            </div>

                            <div style={{ flex: "0.9" }}>
                              <p className='fbTitle'>Roboket</p>
                              <div className='fbSponsor'>
                                <div>
                                  <p className='sponsorTextInstagram'>Sponsored</p>
                                </div>
                              </div>
                            </div>

                            <div className='sponsor' style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
                              Sponsored <img src={threeDot} alt='three dot' />
                            </div>
                          </div>
                          <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                            {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                          </div>
                          <Panel>
                            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAd' />
                          </Panel>
                          <hr />
                          <Panel>
                            <div>{descriptionUrl.length === 0 ? "adndiginet.com" : descriptionUrl.split(".com")[0] + ".com"}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>
                            </div>
                            <p style={{ textAlign: "center", fontSize: "16px" }}>
                              {calltoAction === "NO_BUTTON" && <></>}
                              {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
                              {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
                              {calltoAction === "DOWNLOAD" && <span>Download</span>}
                              {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
                              {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
                              {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
                              {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
                              {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
                              {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
                              {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
                              {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
                              {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
                              {">"}
                            </p>
                          </Panel>
                        </Panel>
                      )}

                      {previewState === "General Search Results (Mobile)" && (
                        <Panel shaded bordered bodyFill className='panelFormGeneral'>
                          <div className='fbSponsorNew' style={{ padding: "0px 25px" }}>
                            <div className='iconTool'>
                              <img className='circleAshColor' src={circleIcon} alt='circleAshColor' />
                              <img
                                className='flagIconStyle'
                                src={flagIcon}
                                alt='flagIcon'
                                style={{
                                  position: "absolute",
                                  top: 130,
                                  left: 68,
                                  width: "25px",
                                  height: "22px"
                                }}
                              />
                            </div>

                            <div>
                              <p className='fbTitle'>Roboket</p>
                              <div className='fbSponsorNew'>
                                <div>
                                  <p className='sponsorText'>Sponsored</p>
                                </div>
                                <div>
                                  <img src={earthIcon} alt='earth icon' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div style={{ padding: "6px 25px", overflow: "hidden" }}>
                            {description.length === 0 ? <>Your primary text</> : <>{description}</>}
                          </div>
                          <div>
                            <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgFbAdGen' />
                          </div>

                          <Panel>
                            <div>{descriptionUrl.length === 0 ? "adndiginet.com" : descriptionUrl.split(".com")[0] + ".com"}</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                              {calltoAction === "NO_BUTTON" && <></>}

                              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                            </div>
                          </Panel>
                        </Panel>
                      )}

                      {previewState === "Marketplace Search Results (Mobile)" && <MarketPlace file={file} title={title} />}

                      {previewState === "Audience Network Interstitial" && (
                        <AudiNetWorkInter description={description} title={title} calltoAction={calltoAction} file={file} />
                      )}

                      {previewState === "Audience Network Native" && (
                        <AudiNetWorkNative title={title} calltoAction={calltoAction} file={file} />
                      )}

                      {previewState === "Audience Network Banner" && (
                        <AudiNetBanner calltoAction={calltoAction} description={description} />
                      )}

                      {previewState === "Instagram Feed" && <InstaFeed file={file} description={description} calltoAction={calltoAction} />}

                      {previewState === "Instagram Stories" && (
                        <InstagramStory file={file} description={description} calltoAction={calltoAction} />
                      )}

                      {previewState === "Instagram Explore Feed" && (
                        <InstagramStoryFeed file={file} description={description} calltoAction={calltoAction} />
                      )}

                      {previewState === "Instant Articles" && (
                        <InstantArticle file={file} description={description} title={title} calltoAction={calltoAction} />
                      )}

                      {previewState === "Facebook Stories" && (
                        <InstagramStory file={file} description={description} calltoAction={calltoAction} />
                      )}

                      {previewState === "Messenger Stories" && (
                        <MessengerStory file={file} description={description} calltoAction={calltoAction} />
                      )}

                      {previewState === "Marketplace Category Feed & Details Page" && <MarketPlace file={file} title={title} />}
                    </>
                  )}

                  {isChecked === "Web" && (
                    <div className='uploadAdd__modal-right panelForm'>
                      <div className='typeofAdd_card detailsTypeofAdd_card'>
                        <div className='uploadAdd__modal-right-midContent'>
                          <img src={URL?.createObjectURL(file[0])} alt='file_preview' className='imgBannerWeb' />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Grid>

            <div className='uploadAdd__modal-typeofAdd-footer'>
              <div className='uploadAdd__modal-typeofAdd-footer-content last-page-footer-content'>
                <button
                  onClick={() => {
                    setChooseType("")
                  }}
                >
                  Back
                </button>
                <Button
                  disabled={isChecked === "" || isCreating}
                  onClick={handleCreateAdd}
                  className={isCreating ? "nonActiveFooterButton" : "activeFooterButton"}
                >
                  {isCreating ? "Creating..." : "Create"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal className='__generic_modal' show={fbModal} full onHide={handleCloseList}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Select a Page to Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading === true ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <div>
              {Array.isArray(fbList) && fbList.length > 0 ? (
                <List hover vertical>
                  {fbList.map((fbListIndex) => (
                    <label key={fbListIndex.eid}>
                      <List.Item style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
                          <div style={{ paddingLeft: "30px" }}>
                            <input
                              type='radio'
                              name='fbListRadio'
                              checked={selectedEid === fbListIndex.eid}
                              onChange={() => handleRadioChange(fbListIndex.eid, fbListIndex.name, fbListIndex.url)}
                            />
                          </div>
                          <div>{fbListIndex.name}</div>
                          <div>{fbListIndex.url}</div>
                        </div>
                      </List.Item>
                    </label>
                  ))}
                </List>
              ) : (
                <div>
                  {" "}
                  <p className='NoItemTxt'> No page found </p>{" "}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        {loading === false && (
          <Modal.Footer style={{ padding: "10px" }}>
            <Button onClick={handleCloseList} color='green' appearance='primary'>
              Ok
            </Button>
            <Button onClick={handleCloseList} color='red' appearance='primary'>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export default TypeofAdd
