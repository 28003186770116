// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableWidthFb {
  width: 100%;
  background-color: white;
}
.tableWidthFb .koyZDM {
  height: 64vh !important;
  overflow-y: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/facebookAds/facebookAds.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uBAAA;AACF;AAAE;EACE,uBAAA;EACA,2BAAA;AAEJ","sourcesContent":[".tableWidthFb {\n  width: 100%;\n  background-color: white;\n  .koyZDM {\n    height: 64vh !important;\n    overflow-y: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
