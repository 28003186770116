import React, { useEffect, useState } from "react"
import { Alert, Button, ButtonToolbar, Content, FlexboxGrid, Header, Input, Modal, Radio, RadioGroup, SelectPicker } from "rsuite"
import settingsIcon from "../../assets/svgs/settingsIcon.svg"
import BoxPanel from "src/components/AddRollPanel/BoxPanel/BoxPanel"
import "../FacebookAdRoll/facebookAdRoll.scss"
import { retargetingCamp } from "src/components/AddRollPanel/BoxPanel/api-panel"
import channelSvg from "../../assets/svgs/channel.svg"
import chooseScheduleSvg from "../../assets/svgs/chooseSchedule.svg"
import webSvg from "../../assets/svgs/webForCampaign.svg"
import adgroupSvg from "../../assets/svgs/adGroup.svg"
import budget from "../../assets/svgs/budgetIcon.svg"
import { useHistory } from "react-router-dom"
import {
  campaignObjectiveData,
  campaignObjectiveDataRetarget,
  campaignOptimizationGoal,
  campaignOptimizationGoalLink,
  campaignOptimizationGoalRetarget
} from "src/components/mocks/mock-data"
import { FbAdsType } from "src/common/utils/types"
import { useProviderId } from "src/common/constants"

interface selectedRowsImgType {
  image_url: string
  height: number
  width: number
  adroll_ad_eid: string
}

interface selectedRowsAudType {
  eid: string
}

interface suggestionType {
  eid: string
}

const FacebookAddRoll = (props: any) => {
  const { selectedCardGoal } = props

  const todaysDate = new Date().toISOString().split("T")[0] + "T" + new Date().toLocaleTimeString(undefined, { hour12: false })

  const today = new Date()
  const nextFiveDays = new Date(today)
  nextFiveDays.setDate(today.getDate() + 6)

  const nextFiveDaysFormate = nextFiveDays.toISOString().split("T")[0] + "T" + new Date().toLocaleTimeString(undefined, { hour12: false })

  const [inputNumberValue, setInputNumberValue] = useState(50)
  const [inputValue, setInputValue] = useState("AdGroup")
  const [selectedRowsImg, setSelectedRowsImg] = useState<selectedRowsImgType[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedRowsAud, setSelectedRowsAud] = useState<selectedRowsAudType[]>([])
  const [bidInput, setBidInput] = useState()
  const [selectedValue, setSelectedValue] = useState("Impressions")
  const [selectedObjValue, setSelectedObjValue] = useState("CONVERSIONS")
  const [selectedOptValue, setSelectedOptValue] = useState("IMPRESSIONS")
  const [bidStrategyValue, setBidStrategyValue] = useState("")
  const [suggestions, setSuggestions] = useState<suggestionType[]>([])
  const [inputValueModal, setInputValueModal] = useState("Facebook Campaign - " + new Date().toLocaleDateString())
  const [selectedStartDate, setSelectedStartDate] = useState(todaysDate)
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [open, setOpen] = React.useState(false)
  const [minAge, setMinAge] = useState(18)
  const [maxAge, setMaxAge] = useState(60)
  const [selectedAdPlacement, setSelectedAdPlacement] = useState("Automatic (Recommended)")
  const [deviceValue, setDeviceValue] = useState()
  const [fbValue, setFbValue] = useState([])
  const [instagramValue, setInstagramValue] = useState()
  const [messengerValue, setMessengerValue] = useState()
  const [audienceNetworkValue, setAudienceNetworkValue] = useState()
  const [draft, setDraft] = useState(false)
  const [targetingType, setTargetingType] = useState("lookalike")
  const [selectedBid, setSelectedBid] = useState("Maximize clicks")
  const [fbAds, setFbAds] = useState<FbAdsType[]>([])
  const [bidValue, setBidValueData] = useState("LOWEST_COST_WITHOUT_CAP")
  const [audTree, setAudTree] = useState([])
  const [checkStartTimeFlag, setCheckStartTimeFlag] = useState(1)

  const handleBidValueChange = (value: string) => {
    setBidValueData(value)
  }

  const handleChangeAudTree = (value: any) => {
    setAudTree(value)
  }

  const getKeyValue = (selectedOption: any) => {
    switch (selectedOption) {
      case "age1":
        return 1
      case "age2":
        return 2
      case "age3":
        return 3
      case "age4":
        return 4
      case "age5":
        return 5
      case "age6":
        return 6
      case "gender1":
        return 1
      case "gender2":
        return 2

      case "Professional degree":
        return 10
      case "College grad":
        return 3
      case "High school grad":
        return 4
      case "Some college":
        return 5
      case "Associate degree":
        return 6
      case "Master's degree":
        return 9

      case "Household income: top 10% of ZIP codes (US)":
        return 6107813551783
      case "Household income: top 25%-50% of ZIP codes (US)":
        return 6107813554583
      case "Household income: top 5% of ZIP codes (US)":
        return 6107813079183

      case "Arts, Entertainment, Sports and Media":
        return 6012903320983
      case "Business Decision Makers":
        return 6377408081983
      case "Cleaning and Maintenance Services":
        return 6012903299983
      case "Companies founded before 2000":
        return 6262428231783

      case "Companies founded between 2000 and 2009":
        return 6012903167783

      case "Companies founded between 2010 and now":
        return 6012903320383

      case "Company revenue: $1M to $10M":
        return 6016671857383

      case "Company revenue: less than $1M":
        return 6377169088983

      case "Company revenue: more than $10M":
        return 6019621029983

      case "Company size: 101-500 employees":
        return 6377169297783

      case "Company size: 11-100 employees":
        return 6012903128783

      case "Company size: more than 500 employees":
        return 6377134922183

      case "Computation and Mathematics":
        return 6377134779583

      case "Construction and Extraction":
        return 6080792228383

      case "Farming, Fishing and Forestry":
        return 6012901802383

      case "Government Employees (Global)":
        return 6080792282783

      case "Healthcare and Medical Services":
        return 6377168689383

      case "IT and Technical Services":
        return 6008888972183

      case "IT Decision Makers":
        return 6012903317583

      case "Legal Services":
        return 6377168992983

      case "Life, Physical and Social Sciences":
        return 6008888961983

      case "Medium business-to-business enterprise employees (200 - 500 employees)":
        return 6377408028783

      case "Military (Global)":
        return 6012903167183

      case "Production":
        return 6012903159383

      case "Small business-to-business enterprise employees (10-200 employees)":
        return 6262428248783

      case "Transportation and Moving":
        return 6377408290383

      case "Veterans (US)":
        return 6012903140583

      case "Facebook access (mobile) - Recently Detected Devices":
        return 6377178995383

      case "Facebook access (mobile) - Recently Detected Iphone 14 Devices":
        return 6202657388783

      case "New Active Business (< 12 months)":
        return 6203619967383

      case "New Active Business (< 24 months)":
        return 6378552460983

      case "New Active Business (< 6 months)":
        return 6356471865383

      case "Shops admins":
        return 6297846662583

      case "All creators":
        return 6378532690183
      case "Facebook Lite app users":
        return 6273108079183
      case "Food and drink creators":
        return 6377407066783
      case "Instagram Business Profile Admins":
        return 6377406843183
      case "Internet personality creators":
        return 6273196847983
      case "Facebook page admins - New Page Admins":
        return 6041891177783
      case "People who have visited Facebook Gaming":
        return 6273108107383
      case "Travel and outdoors creators":
        return 6320095650783

      case "Music creators":
        return 6320095608983
      case "Friends of those who live abroad":
        return 6378518542983

      default:
        return 0
    }
  }

  const getNameValue = (selectedOption: any) => {
    switch (selectedOption) {
      case "age1":
        return "age"
      case "age2":
        return "age"
      case "age3":
        return "age"
      case "age4":
        return "age"
      case "age5":
        return "age"
      case "age6":
        return "age"
      case "gender1":
        return "gender"
      case "gender2":
        return "gender"

      case "Professional degree":
        return "education_level"
      case "College grad":
        return "education_level"
      case "High school grad":
        return "education_level"
      case "Some college":
        return "education_level"
      case "Associate degree":
        return "education_level"
      case "Master's degree":
        return "education_level"

      case "Household income: top 10% of ZIP codes (US)":
        return "income"
      case "Household income: top 25%-50% of ZIP codes (US)":
        return "income"
      case "Household income: top 5% of ZIP codes (US)":
        return "income"

      case "Arts, Entertainment, Sports and Media":
        return "industries"
      case "Business Decision Makers":
        return "industries"
      case "Cleaning and Maintenance Services":
        return "industries"
      case "Companies founded before 2000":
        return "industries"
      case "Companies founded between 2000 and 2009":
        return "industries"
      case "Companies founded between 2010 and now":
        return "industries"
      case "Company revenue: $1M to $10M":
        return "industries"
      case "Company revenue: less than $1M":
        return "industries"
      case "Company revenue: more than $10M":
        return "industries"
      case "Company size: 101-500 employees":
        return "industries"
      case "Company size: 11-100 employees":
        return "industries"
      case "Company size: more than 500 employees":
        return "industries"
      case "Computation and Mathematics":
        return "industries"
      case "Construction and Extraction":
        return "industries"
      case "Farming, Fishing and Forestry":
        return "industries"
      case "Government Employees (Global)":
        return "industries"
      case "Healthcare and Medical Services":
        return "industries"

      case "IT and Technical Services":
        return "industries"
      case "IT Decision Makers":
        return "industries"
      case "Legal Services":
        return "industries"
      case "Life, Physical and Social Sciences":
        return "industries"
      case "Medium business-to-business enterprise employees (200 - 500 employees)":
        return "industries"
      case "Military (Global)":
        return "industries"
      case "Production":
        return "industries"
      case "Small business-to-business enterprise employees (10-200 employees)":
        return "industries"
      case "Transportation and Moving":
        return "industries"
      case "Veterans (US)":
        return "industries"

      case "Facebook access (mobile) - Recently Detected Devices":
        return "behaviors"

      case "Facebook access (mobile) - Recently Detected Iphone 14 Devices":
        return "behaviors"

      case "Digital Activities":
        return "behaviors"

      case "New Active Business (< 12 months)":
        return "behaviors"

      case "New Active Business (< 24 months)":
        return "behaviors"

      case "New Active Business (< 6 months)":
        return "behaviors"

      case "Shops admins":
        return "behaviors"

      case "All creators":
        return "behaviors"
      case "Facebook Lite app users":
        return "behaviors"
      case "Food and drink creators":
        return "behaviors"
      case "Instagram Business Profile Admins":
        return "behaviors"
      case "Internet personality creators":
        return "behaviors"
      case "Facebook page admins - New Page Admins":
        return "behaviors"
      case "People who have visited Facebook Gaming":
        return "behaviors"
      case "Travel and outdoors creators":
        return "behaviors"

      case "Music creators":
        return "behaviors"
      case "Friends of those who live abroad":
        return "behaviors"

      default:
        return 0
    }
  }

  const demography = audTree.map((selectedOption) => {
    return {
      name: getNameValue(selectedOption),
      key: getKeyValue(selectedOption)
    }
  })

  const handleAdPlacementChange = (value: string) => {
    setSelectedAdPlacement(value)
  }

  const handleBidStrategyChange = (value: any) => {
    setSelectedBid(value)
  }

  const handleObjChange = (value: string) => {
    setSelectedObjValue(value)
  }

  const handleOptChange = (value: string) => {
    setSelectedOptValue(value)
  }

  const history = useHistory()

  const handleNumberChange = (event: any) => {
    const newValue = event
    setInputNumberValue(newValue)
  }

  const handleCampStatus = (value: string) => {
    if (value === "Live") {
      setDraft(false)
    } else {
      setDraft(true)
    }
  }

  const handleTargetStatus = (value: string) => {
    if (value === "Lookalike") {
      setTargetingType("lookalike")
    } else {
      setTargetingType("attribute")
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const cardGoaldata = ["Re-targeting", "Lookalike Campaign", "Contextual Campaign"].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "Re-targeting":
        return "retargeting"
      case "Lookalike Campaign":
        return "lookalike"
      case "Contextual Campaign":
        return "contextual"
      default:
        return label
    }
  }

  const handleClose = () => setOpen(false)

  const handleNameChange = (event: any) => {
    const newValue = event
    setInputValue(newValue)
  }

  const handleBidInput = (event: any) => {
    const newValue = event
    setBidInput(newValue)
  }

  const handleSelectChange = (value: any) => {
    setSelectedValue(value)
  }

  const handleSelectedRowsChange = (selectedRows: any) => {
    setSelectedRowsImg(selectedRows)
  }

  const handleMinAgeChange = (value: number) => {
    setMinAge(value)
  }

  const handleMaxAgeChange = (value: number) => {
    setMaxAge(value)
  }

  const handleRowSelectedAud = (state: any) => {
    setSelectedRowsAud(state.selectedRows)
  }

  const handleCancel = () => {
    localStorage.setItem("selectedMenuItem", "create-ads")
    window.location.href = "/ads"
  }

  const handleSubmit = async () => {
    setLoading(true)

    try {
      let payload

      payload = {
        name: inputValueModal,
        start_date: checkStartTimeFlag === 2 ? selectedStartDate : todaysDate,
        end_date: todaysDate === selectedStartDate ? "" : selectedEndDate,
        campaigns: [
          {
            name: inputValueModal,
            key: "2_fb",
            service: "facebook",
            objective: selectedObjValue,
            goal: selectedCardGoal === "retargeting" ? "retarget" : "prospect",
            adgroups: [
              {
                name: inputValue,
                service: "facebook",
                campaign_eid: "2_fb",
                ads: fbAds.map((selectedRowsImgEach) => {
                  return {
                    name: selectedRowsImgEach.name,
                    eid: selectedRowsImgEach.eid,
                    status: "live"
                  }
                }),
                segments: selectedRowsAud.map((selectedRowsImgEach) => {
                  return {
                    eid: selectedRowsImgEach.eid,
                    targeted: true
                  }
                }),
                geotargets:
                  suggestions.length !== 0
                    ? [
                        {
                          eid: suggestions[0]?.eid,
                          targeted: true
                        }
                      ]
                    : [],
                optimization_goal: selectedOptValue,
                min_age: minAge,
                max_age: maxAge,
                targeting: {
                  placements:
                    selectedAdPlacement === "Manual"
                      ? {
                          messenger_positions: messengerValue,
                          audience_network_positions: audienceNetworkValue,
                          facebook_positions: fbValue,
                          instagram_positions: instagramValue,
                          device_platforms: deviceValue
                        }
                      : {
                          automatic: true
                        }
                }
              }
            ],
            targeting_type: targetingType,
            daily_budget: inputNumberValue,
            bid_strategy: selectedBid === "Set your own performance target" ? bidValue : "automatic"
          }
        ],
        budget: {
          type: "daily",
          goal: inputNumberValue
        },
        currency: "USD",
        budget_balancing_automated: false,
        source: "automation",
        is_draft: draft
      }

      if (selectedBid === "Set your own performance target") {
        payload.campaigns = payload.campaigns.map((campaign) => ({
          ...campaign,
          kpi: {
            metric: bidStrategyValue,
            goal: bidInput
          }
        }))
      }

      // if (selectedCardGoal === "lookalike") {
      //   payload.campaigns.adgroups.attribute_targets : demography
      // }

      // Iterate over campaigns
      payload.campaigns.forEach((campaign) => {
        campaign.adgroups.forEach((adgroup) => {
          if (selectedCardGoal === "lookalike") {
            ;(adgroup as any).attribute_targets = demography
          }

          // if (selectedBid === "Set your own performance target") {
          //   (adgroup as any).kpi = {
          //     metric: bidStrategyValue,
          //     goal: bidInput
          //   }
          // }
        })
      })

      const providerId = await useProviderId()

      const campSubmit = await retargetingCamp(providerId, payload)

      if (campSubmit.status === 201 || campSubmit.status === 200) {
        Alert.success("Campaign create successfully")
        localStorage.setItem("selectedMenuItem", "campaign-list")
        history.push("/ads")
      } else {
        Alert.error("error")
      }
    } catch (error) {
      Alert.error("Error in API request")
    } finally {
      setLoading(false)
    }
  }

  const handleModalInputChange = (event: any) => {
    const newValue = event
    setInputValueModal(newValue)
  }

  useEffect(() => {
    if (selectedValue === "Impressions") {
      setBidStrategyValue("CPM")
    } else if (selectedValue === "Conversion") {
      setBidStrategyValue("CPA")
    } else if (selectedValue === "Clicks") {
      setBidStrategyValue("CPC")
    }
  }, [selectedValue])

  return (
    <>
      <Content className='main_content'>
        <Header>
          <div className='cardTitle'>
            <div>
              <p style={{ color: "#8c8c8c", fontSize: "16px" }}>Facebook Campaign</p>

              <div className='titleEdit'>
                <p className='titleCampHeader'> {inputValueModal} </p>

                <div onClick={handleOpen} className='monty-proxy-edit-name'>
                  Edit Name
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div className='__dashboard_container'>
          <div className='addRoll__container-area'>
            <Content className='addRoll__container-area-content'>
              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      selectedCardChanel={"Facebook"}
                      boxSubTitle={"Facebook"}
                      facebookFlag={"facebookFlag"}
                      handleOpen={handleOpen}
                      imgS={channelSvg}
                      title={"Campaign Settings"}
                      boxBody={"ChannelsBody"}
                      boxtitle={"Channels"}
                      handleModalInputChange={handleModalInputChange}
                      buttonToolbar={
                        <div className='settingsBox'>
                          <img src={settingsIcon} alt='s' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        </div>
                      }
                      selectedCardGoal={selectedCardGoal}
                      setInputValueModal={setInputValueModal}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={chooseScheduleSvg}
                      boxSubTitle={"Starts immediately upon approval, no end date"}
                      boxtitle={"Choose Schedule"}
                      boxBody={"ScheduleBody"}
                      loading={loading}
                      setLoading={setLoading}
                      setSelectedStartDate={setSelectedStartDate}
                      setSelectedEndDate={setSelectedEndDate}
                      nextFiveDays={nextFiveDays}
                      setCheckStartTimeFlag={setCheckStartTimeFlag}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid' style={{ paddingTop: "2%" }}>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={webSvg}
                      boxSubTitle={"Optimized for conversions, automatic CPA"}
                      title={"Facebook & Instagram Channels"}
                      boxtitle={"Choose Facebook Settings"}
                      boxBody={"facebookSettingsBody"}
                      handleBidInput={handleBidInput}
                      selectedValue={selectedValue}
                      handleSelectChange={handleSelectChange}
                      selectedBid={selectedBid}
                      handleBidStrategyChange={handleBidStrategyChange}
                      handleBidValueChange={handleBidValueChange}
                      bidValue={bidValue}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={adgroupSvg}
                      boxSubTitle={"Ad group settings, audience and ads"}
                      boxtitle={"Configure" + " " + inputValue}
                      boxBody={"FacebookConfigureAdBody"}
                      handleNameChange={handleNameChange}
                      inputValue={inputValue}
                      handleSelectedRowsChange={handleSelectedRowsChange}
                      selectedRowsImg={selectedRowsImg}
                      handleRowSelectedAud={handleRowSelectedAud}
                      setSuggestions={setSuggestions}
                      suggestions={suggestions}
                      selectedCardGoal={selectedCardGoal}
                      loading={loading}
                      setLoading={setLoading}
                      handleMinAgeChange={handleMinAgeChange}
                      handleMaxAgeChange={handleMaxAgeChange}
                      handleAdPlacementChange={handleAdPlacementChange}
                      setFbAds={setFbAds}
                      selectedAdPlacement={selectedAdPlacement}
                      deviceValue={deviceValue}
                      setDeviceValue={setDeviceValue}
                      setFbValue={setFbValue}
                      setInstagramValue={setInstagramValue}
                      setMessengerValue={setMessengerValue}
                      setAudienceNetworkValue={setAudienceNetworkValue}
                      fbValue={fbValue}
                      instagramValue={instagramValue}
                      messengerValue={messengerValue}
                      audienceNetworkValue={audienceNetworkValue}
                      selectedRowsAud={selectedRowsAud}
                      targetingType={targetingType}
                      handleChangeAudTree={handleChangeAudTree}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid' style={{ paddingTop: "2%" }}>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={budget}
                      boxSubTitle={"Smart Budget optimization across channels or separate budgets"}
                      boxtitle={"Choose Budget"}
                      title={"Campaign Budget"}
                      boxBody={"ChooseBudgetBody"}
                      handleNumberChange={handleNumberChange}
                      inputNumberValue={inputNumberValue}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>
            </Content>
          </div>
        </div>
        <div className='finishBtnTop'>
          <ButtonToolbar>
            <Button appearance='default' className='cancelBtn' onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} className='finishBtn' disabled={loading}>
              {loading ? "Finishing..." : "Finish"}
            </Button>
          </ButtonToolbar>
        </div>
      </Content>

      <Modal className='__generic_modalManageCamp' show={open} onHide={handleClose}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Manage Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body_camp' style={{ padding: "0px 20px" }}>
          <div>
            <p className='titleCamp'>Campaign Name</p>
            <Input defaultValue={inputValueModal} onChange={handleModalInputChange} />
            <br />
            <p className='titleCamp'>Campaign Type</p>
            <SelectPicker size='md' appearance='default' defaultValue={selectedCardGoal} disabled cleanable={false} data={cardGoaldata} />

            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Targeting Type : </p>

              <RadioGroup name='radioList' inline defaultValue={targetingType} onChange={handleTargetStatus}>
                <Radio value='lookalike'>
                  <p style={{ fontWeight: "500" }}>Lookalike</p>
                </Radio>
                <Radio value='attribute'>
                  <p style={{ fontWeight: "500" }}>Attribute</p>
                </Radio>
              </RadioGroup>
            </div>

            <p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
              Campaign Objective
            </p>

            {selectedCardGoal === "retargeting" ? (
              <>
                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  data={campaignObjectiveData}
                  defaultValue={selectedObjValue}
                  style={{ width: 184 }}
                  onChange={handleObjChange}
                />

                <p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
                  Optimization Goal
                </p>

                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  data={selectedObjValue === "CONVERSIONS" ? campaignOptimizationGoal : campaignOptimizationGoalLink}
                  defaultValue={selectedOptValue}
                  style={{ width: 184 }}
                  onChange={handleOptChange}
                />
              </>
            ) : (
              <>
                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  data={campaignObjectiveDataRetarget}
                  defaultValue={selectedObjValue}
                  style={{ width: 184 }}
                  onChange={handleObjChange}
                />

                <p className='titleCamp' style={{ marginBottom: "1%", marginTop: "2%" }}>
                  Optimization Goal
                </p>

                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  data={selectedObjValue === "CONVERSIONS" ? campaignOptimizationGoalRetarget : campaignOptimizationGoalLink}
                  defaultValue={selectedOptValue}
                  style={{ width: 184 }}
                  onChange={handleOptChange}
                />
              </>
            )}

            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Campaign Status : </p>

              <RadioGroup name='radioList' inline defaultValue={draft === false ? "Live" : "Draft"} onChange={handleCampStatus}>
                <Radio value='Live'>
                  <p style={{ fontWeight: "500" }}>Live</p>
                </Radio>
                <Radio value='Draft'>
                  <p style={{ fontWeight: "500" }}>Draft</p>
                </Radio>
              </RadioGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "10px" }}>
          <Button onClick={handleClose} style={{ backgroundColor: "#0fae8c", color: "white", padding: "8px 28px" }}>
            Ok
          </Button>
          <Button onClick={handleClose} color='red' appearance='primary'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FacebookAddRoll
