import { Alert } from "rsuite"
import { pinterast, pinterastFacebook, web, web_native } from "src/assets"

export const rulesResulationObject = (aspectRatio: number, width: number, height: number, size: number) => {
  const result = []

  if (
    (width === 300 && height === 250) ||
    (width === 300 && height === 600) ||
    (width === 160 && height === 600) ||
    (width === 970 && height === 250) ||
    (width === 728 && height === 90) ||
    (width === 320 && height === 50)
  ) {
    result.push("Web")
  }

  if ((width === 600 && height === 600) || (width === 600 && height === 315) || (width === 600 && height === 500)) {
    result.push("NativeAds")
  }

  if ((width === 600 && height >= 315) || width == height) {
    result.push("FacebookInstagramImage")
  }

  if (size > 151000) {
    return "size error"
  }

  return result.length > 0 ? result : ["default"]
}

export const typedOfAdd = [
  {
    id: "Web",
    title: "Web",
    content: "Connect",
    icon: web
  },
  {
    id: "NativeAds",
    title: "Web Native",
    content: "Connect",
    icon: web_native
  },
  {
    id: "FacebookInstagramImage",
    title: "Facebook & Instagram",
    content: "Connect",
    icon: pinterastFacebook
  },
  {
    id: "Pinterest",
    title: "Pinterest",
    content: "Connect",
    icon: pinterast
  }
]

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
