import DataTable from "react-data-table-component"
import LoadingComponent from "../loading/Loading"
import "./facebookAds.scss"

const FacebookAds = (props: any) => {
  const { setFbAds, onSelectedRowsChange, load, dataFacebook } = props

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        borderRadius: "0px",
        backgroundColor: "white"
      }
    },
    headCells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "12px"
      }
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  }

  const columnsForAdList = [
    {
      name: "Asset",
      sortable: true,
      selector: (row: any) => {
        return (
          <div>
            <img height='50px' width='80px' src={row.image_url} alt='assets' />
          </div>
        )
      },
      width: "140px"
    },
    {
      name: "Name",
      sortable: true,
      selector: (row: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div className='nameProperty'>{row.name}</div>

            <div style={{ fontSize: "10px" }} className='text-muted'>
              {String(row?.type).toLocaleUpperCase()} | size: {row?.width} * {row?.height}
            </div>
          </div>
        )
      },
      width: "260px"
    },
    {
      name: "Ad Status",
      sortable: true,
      selector: (row: any) => {
        return <div>{row.status ?? "not found"}</div>
      },
      width: "120px"
    },
    {
      name: "Landing Page",
      sortable: true,
      selector: (row: any) => {
        return <div>&nbsp;{row.landingPage ?? "not found"}</div>
      },
      width: "150px"
    },
    {
      name: "Created",
      sortable: true,
      selector: (row: any) => {
        const createdDate = new Date(row.created_date)
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "short",
          year: "numeric"
        }

        const formattedUpdatedDate = createdDate.toLocaleDateString("en-US", options)

        return <div>&nbsp;{formattedUpdatedDate}</div>
      },
      width: "180px"
    }
  ]

  const handleRowSelectedFb = (selectedRows: any) => {
    setFbAds(selectedRows.selectedRows)

    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedRows?.selectedRows)
    }
  }

  return (
    <>
      {load ? (
        <>
          <LoadingComponent />
        </>
      ) : (
        <div className='tableWidthFb'>
          <DataTable
            columns={columnsForAdList as any}
            selectableRows
            onSelectedRowsChange={handleRowSelectedFb}
            data={dataFacebook}
            pagination={true}
            customStyles={customStyles}
          />
        </div>
      )}
    </>
  )
}

export default FacebookAds
