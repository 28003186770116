import "../styles/TypeofAdd.scss"
import { Button } from "rsuite"
import flagIcon from "../../../assets/svgs/flagIcon.svg"

type AudiNetworkType = {
  calltoAction: string
  description: string
}

const AudiNetBanner = (props: AudiNetworkType) => {
  const { description, calltoAction } = props
  return (
    <>
      <div className='panelFormAudiBanner'>
        <div className='iconToolBanner'>
          <img
            className='flagIconStyle'
            src={flagIcon}
            alt='flagIcon'
            style={{
              marginTop: "9%"
            }}
          />
        </div>

        <div style={{ width: "71%" }}>
          <p style={{ textAlign: "center", marginTop: "2%",overflow:'hidden' }}>{description.length === 0 ? <>Your Message</> : <>{description}</>}</p>
        </div>

        <div>
          {calltoAction === "NO_BUTTON" && <></>}

          {calltoAction === "APPLY_NOW" && <Button className='btnActionBanner'>Apply Now</Button>}

          {calltoAction === "BOOK_TRAVEL" && <Button className='btnActionBanner'>Book Now</Button>}

          {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

          {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

          {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

          {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

          {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

          {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

          {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

          {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

          {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

          {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
        </div>
      </div>
    </>
  )
}

export default AudiNetBanner
