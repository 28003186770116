import { Alert, Button, Modal } from "rsuite"
import CreateNativeAdsBody from "src/pages/CreateNativeAds/CreateNativeAdsBody"
import FacebookAds from "../components/facebookAds/FacebookAds"
import Adlibrary from "src/pages/AdLibrary/Adlibrary"
import WebAds from "../components/webAds/WebAds"
import { useEffect, useState } from "react"
import { useProviderId } from "../constants"
import { providerListAd } from "src/pages/provider/api-provider"
import { fetchWebAds } from "../components/webAds/api-webads"
import { fetchFbAds } from "../components/facebookAds/api-facebookAds"

const FbAdsModal = (props: any) => {
  const { openList, handleCloseList, handleSelectedRowsChange, boxBody, setFbAds, setWebAds } = props

  const [load, setLoad] = useState(false)
  const [dataWeb, setDataWeb] = useState<any>([])
  const [dataFacebook, setDataFacebook] = useState<any>([])

  const getWebAds = async () => {
    const providerId = await useProviderId()
    try {
      setLoad(true)
      const providerInfo = await providerListAd()
      if (providerInfo) {
        const ads: any = await fetchWebAds(providerId, "web")
        setDataWeb(ads)
      } else {
        Alert.error("Server Down")
      }
    } catch (error) {
      console.error(error)
      Alert.error("Error")
    } finally {
      setLoad(false)
    }
  }

  const getFacebookAds = async () => {
    try {
      setLoad(true)
      const providerInfo = await providerListAd()
      if (providerInfo) {
        const providerId: any = await fetchFbAds(providerInfo.data[0]?.UserProviderAssociation?.provider_id, "facebook")
        setDataFacebook(providerId)
      } else {
        Alert.error("Server Down")
      }
    } catch (error) {
      console.error(error)
      Alert.error("Error")
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (boxBody === "FacebookConfigureAdBody") {
      getFacebookAds()
    } else {
      getWebAds()
    }
  }, [])

  return (
    <>
      {boxBody === "FacebookConfigureAdBody" ? (
        <>
          <Modal backdrop={true} className='__generic_modal' full show={openList} onHide={handleCloseList}>
            <Modal.Header className='modal-header-custom'>
              <Modal.Title className='title'>Facebook Ads</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal__body nativeAdBody' style={{ overflow: "auto", maxHeight: "75vh", padding: "20px 30px" }}>
              <FacebookAds load={load} dataFacebook={dataFacebook} onSelectedRowsChange={handleSelectedRowsChange} setFbAds={setFbAds} />
            </Modal.Body>
            {load === true ? (
              <></>
            ) : (
              <Modal.Footer style={{ padding: "14px", paddingRight: "23px" }}>
                <Button onClick={handleCloseList} style={{ padding: "9px 28px" }} color='green' appearance='primary'>
                  Ok
                </Button>
                <Button onClick={handleCloseList} color='red' appearance='primary'>
                  Cancel
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </>
      ) : (
        <>
          <Modal className='__generic_modalAds adsModal' show={openList} full onHide={handleCloseList}>
            <Modal.Header className='modal-header-custom'>
              <Modal.Title className='title'>Choose Ads</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <WebAds load={load} dataWeb={dataWeb} onSelectedRowsChange={handleSelectedRowsChange} setWebAds={setWebAds} />
            </Modal.Body>
            {load === true ? (
              <></>
            ) : (
              <Modal.Footer style={{ padding: "23px" }}>
                <Button onClick={handleCloseList} style={{ padding: "9px 28px" }} color='green' appearance='primary'>
                  Ok
                </Button>
                <Button onClick={handleCloseList} color='red' appearance='primary'>
                  Cancel
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </>
      )}
    </>
  )
}

export default FbAdsModal
