import { Col, Modal, Row } from "rsuite"
import { getDynamicUrl } from "../helpers/helpers"

const PreviewAdModal = (props: any) => {
  const { selectedRows, openPreviewAd, handleClose } = props

  return (
    <div className='previewModal'>
      <Modal className='__generic_modal' show={openPreviewAd} onHide={handleClose} overflow size='lg'>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'> {selectedRows?.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body' style={{ overflow: "auto", maxHeight: "100vh", padding: "20px 30px" }}>
          <div className='modalFlex'>
            <div>
              <img src={selectedRows?.image_url} style={{ objectFit: "cover" }} height='400px' width='400px' alt='' />
            </div>

            <div>
              <p title={getDynamicUrl(selectedRows)} style={{ fontWeight: "600", fontSize: "16px" }}>
                Landing Page -{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  <a style={{ color: "#2c9fdd" }} href={getDynamicUrl(selectedRows)} target='_blank'>
                    {getDynamicUrl(selectedRows).length > 30
                      ? `${getDynamicUrl(selectedRows).slice(0, 30)}...`
                      : getDynamicUrl(selectedRows)}{" "}
                  </a>{" "}
                </span>{" "}
              </p>
              {/* <p style={{ color: "#2c9fdd" }}>
                {" "}
                
              </p> */}
              <p style={{ fontWeight: "600", fontSize: "16px" }}>
                Size of image -{" "}
                <span style={{ color: "#2c9fdd", fontWeight: "500" }}>
                  {selectedRows?.width}*{selectedRows?.height}
                </span>{" "}
              </p>

              <p style={{ fontWeight: "600", fontSize: "16px" }}>
                Status - <span style={{ color: "#2c9fdd", fontWeight: "500" }}>{selectedRows?.status} </span>{" "}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}></Modal.Footer>
      </Modal>
    </div>
  )
}

export default PreviewAdModal
