import { Button, Col, Modal, Panel, Row, SelectPicker } from "rsuite"
import {
  getDynamicBrandName,
  getDynamicCallAction,
  getDynamicDescription,
  getDynamicImageSrc,
  getDynamicTitle,
  getDynamicUrl
} from "../helpers/helpers"
import flagIcon from "../../assets/svgs/flagIcon.svg"
import earthIcon from "../../assets/svgs/earth.svg"
import likeIcon from "../../assets/svgs/like.svg"
import commentIcon from "../../assets/svgs/comment.svg"
import shareIcon from "../../assets/svgs/share.svg"
import circleIcon from "../../assets/svgs/cricleBackground.svg"
import threeDot from "../../assets/svgs/threeDotHorizon.svg"
import { useEffect, useState } from "react"
import MarketPlace from "src/components/UploadAdd/Preset/MarketPlace"
import AudiNetWorkInter from "src/components/UploadAdd/Preset/AudiNetWorkInter"
import AudiNetWorkNative from "src/components/UploadAdd/Preset/AudiNetWorkNative"
import AudiNetBanner from "src/components/UploadAdd/Preset/AudiNetBanner"
import InstaFeed from "src/components/UploadAdd/Preset/InstaFeed"
import InstagramStory from "src/components/UploadAdd/Preset/InstagramStory"
import InstagramStoryFeed from "src/components/UploadAdd/Preset/InstagramExploreFeed"
import InstantArticle from "src/components/UploadAdd/Preset/InstantArticle"
import MessengerStory from "src/components/UploadAdd/Preset/MessengerStory"
import previousBtn from "../../assets/svgs/previousBtnSvg.svg"
import nextBtn from "../../assets/svgs/nextBtnsvg.svg"

const viewData = [
  "Desktop News Feed",
  "Desktop Right Column",
  "Mobile News Feed",
  "Desktop Marketplace Feed",
  "Desktop Marketplace Details Page",
  "Mobile Marketplace Feed",
  "General Search Results (Mobile)",
  "Marketplace Search Results (Mobile)",
  "Audience Network Interstitial",
  "Audience Network Native",
  "Audience Network Banner",
  "Instagram Feed",
  "Instagram Stories",
  "Instagram Explore Feed",
  "Instant Articles",
  "Facebook Stories",
  "Messenger Stories",
  "Marketplace Category Feed & Details Page"
].map((item) => ({ label: item, value: item }))

const PreviewAdModal = (props: any) => {
  const { selectedRows, openPreviewAd, handleClose, adType } = props
  const [previewState, setPreviewState] = useState(viewData[0].value)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [destinationUrl, setDestinationUrl] = useState("")
  const [brandName, setBrandName] = useState("")
  const [callAction, setCallAction] = useState("")
  const [object, setObject] = useState(selectedRows)
  const [calltoAction, setCalltoAction] = useState("")

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1
      setPreviewState(viewData[prevIndex].value)
      setCurrentIndex(prevIndex)
    }
  }

  const handleNext = () => {
    if (currentIndex < viewData.length - 1) {
      const nextIndex = currentIndex + 1
      setPreviewState(viewData[nextIndex].value)
      setCurrentIndex(nextIndex)
    }
  }

  const handlePreviewChange = (value: any) => {
    setPreviewState(value)
    const newIndex = viewData.findIndex((item) => item.value === value)
    setCurrentIndex(newIndex)
  }

  useEffect(() => {
    setCalltoAction(getDynamicCallAction(selectedRows))
  }, [selectedRows])

  useEffect(() => {
    if (selectedRows.hasOwnProperty("name")) {
      setName(selectedRows?.name)
      setTitle(getDynamicTitle(selectedRows) ?? "")
      setDescription(getDynamicDescription(selectedRows) ?? "")
      setDestinationUrl(getDynamicUrl(selectedRows) ?? "")
      setBrandName(getDynamicBrandName(selectedRows) ?? "")
      setCallAction(getDynamicCallAction(selectedRows) ?? "")
    }
  }, [object])

  useEffect(() => {
    setObject(selectedRows)
  }, [selectedRows])

  return (
    <div className='previewModal'>
      <Modal className='__generic_modal' show={openPreviewAd} onHide={handleClose} overflow size='lg'>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'> {selectedRows?.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body' style={{ overflow: "auto", maxHeight: "73vh", padding: "20px 30px" }}>
          <div className='modalFlex'>
            <div style={{ width: "50%" }}>
              {selectedRows?.type === "facebook" && (
                <div>
                  <div className='adPreviewSelectPickerAndBtn'>
                    <div>
                      <SelectPicker
                        searchable={false}
                        cleanable={false}
                        value={previewState}
                        data={viewData}
                        style={{ width: 294 }}
                        onChange={handlePreviewChange}
                      />
                    </div>
                    <div>
                      <Button onClick={handlePrevious}>
                        <img src={previousBtn} alt='Previous' />
                      </Button>{" "}
                      &nbsp;
                      <Button onClick={handleNext}>
                        <img src={nextBtn} alt='Next' />
                      </Button>
                    </div>
                  </div>

                  <div style={{ marginTop: "15px" }}>
                    {previewState === "Desktop News Feed" && (
                      <Panel shaded bordered bodyFill className='panelFormViewAd'>
                        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                          <div className='ar-ad-manager-fb-preview-placeholder'>
                            <img
                              src={flagIcon}
                              alt='flagIcon'
                              style={{
                                alignItems: "center"
                              }}
                            />
                          </div>

                          <div>
                            <p className='fbTitle'>Roboket</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <div>
                                <p className='sponsorText'>Sponsored</p>
                              </div>
                              <div>
                                <img src={earthIcon} alt='earth icon' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                          {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                        </div>
                        <Panel>
                          <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                        </Panel>
                        <hr />
                        <Panel>
                          <div style={{ overflow: "hidden" }}>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px"
                            }}
                          >
                            <p className='tilePanel' style={{ overflow: "auto" }}>
                              {title.length === 0 ? "Your Title" : title}
                            </p>
                            <div style={{ flex: "0.3" }}>
                              {calltoAction === "NO_BUTTON" && <></>}

                              {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                              {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                              {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                              {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                              {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                              {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                              {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                              {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                              {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                              {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                              {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                              {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                            </div>
                          </div>
                        </Panel>

                        <Panel>
                          <div className='likeShareIcon'>
                            <div className='displayed'>
                              <img src={likeIcon} alt='likeIcon' />
                              Like
                            </div>
                            <div className='displayed'>
                              <img src={commentIcon} alt='commentIcon' />
                              Comment
                            </div>
                            <div className='displayed'>
                              <img src={shareIcon} alt='shareIcon' />
                              Share
                            </div>
                          </div>
                        </Panel>
                      </Panel>
                    )}

                    {previewState === "Desktop Right Column" && (
                      <Panel shaded bordered bodyFill className='panelFormViewAd'>
                        <div className='adDiffPreview'>
                          <div className='flexDir'>
                            <div style={{ width: "171px" }}>
                              <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                            </div>

                            <div>
                              <p style={{ fontSize: "14px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                              <p style={{ fontSize: "11px", fontWeight: "300" }}>
                                {destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}
                              </p>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </Panel>
                    )}

                    {previewState === "Mobile News Feed" && (
                      <Panel shaded bordered bodyFill className='panelFormMobile panelFormViewAd'>
                        <div style={{ padding: "10px", display: "flex", flex: "1", alignItems: "center" }}>
                          <div className='ar-ad-manager-fb-preview-placeholder'>
                            <img
                              src={flagIcon}
                              alt='flagIcon'
                              style={{
                                alignItems: "center"
                              }}
                            />
                          </div>

                          <div>
                            <p className='fbTitle'>Roboket</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <div>
                                <p className='sponsorText'>Sponsored</p>
                              </div>
                              <div>
                                <img src={earthIcon} alt='earth icon' />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                          {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                        </div>
                        <Panel>
                          <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                        </Panel>
                        <hr />
                        <Panel>
                          <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                            {calltoAction === "NO_BUTTON" && <></>}

                            {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                            {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                            {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                            {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                            {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                            {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                            {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                            {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                            {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                            {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                            {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                            {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                          </div>
                        </Panel>

                        <Panel>
                          <div className='likeShareIcon'>
                            <div className='displayed'>
                              <img src={likeIcon} alt='likeIcon' />
                              Like
                            </div>
                            <div className='displayed'>
                              <img src={commentIcon} alt='commentIcon' />
                              Comment
                            </div>
                            <div className='displayed'>
                              <img src={shareIcon} alt='shareIcon' />
                              Share
                            </div>
                          </div>
                        </Panel>
                      </Panel>
                    )}

                    {previewState === "Desktop Marketplace Feed" && (
                      <>
                        <div className='adDiffPreview'>
                          <div style={{ width: "171px" }}>
                            <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                          </div>
                        </div>
                        <div style={{ padding: "0px 20px" }}>
                          <p style={{ fontSize: "12px", fontWeight: "700" }}>{title.length === 0 ? "Your Title" : title}</p>
                          <p style={{ fontSize: "11px", fontWeight: "300" }}>
                            {destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}
                          </p>
                        </div>
                      </>
                    )}

                    {previewState === "Desktop Marketplace Details Page" && (
                      <>
                        <div className='adDiffPreview'>
                          <div className='iconTool'>
                            <img className='circleAshColor' style={{ width: "51px" }} src={circleIcon} alt='circleAshColor' />
                            <img
                              className='flagIconStyle'
                              src={flagIcon}
                              alt='flagIcon'
                              style={{
                                position: "absolute",
                                top: 130,
                                left: 67,
                                width: "25px",
                                height: "25px"
                              }}
                            />
                          </div>

                          <div style={{ width: "271px" }}>
                            <p className='fbTitleDesktop'>Roboket</p>
                            <img
                              style={{ borderRadius: "19px" }}
                              src={getDynamicImageSrc(selectedRows)}
                              alt='file_preview'
                              className='imgFbAd'
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {previewState === "Mobile Marketplace Feed" && (
                      <Panel shaded bordered bodyFill className='panelFormMobile panelFormViewAd'>
                        <div style={{ padding: "10px 15px", display: "flex", flex: "1", alignItems: "center" }}>
                          <div className='ar-ad-manager-fb-preview-placeholder'>
                            <img
                              src={flagIcon}
                              alt='flagIcon'
                              style={{
                                alignItems: "center"
                              }}
                            />
                          </div>

                          <div style={{ flex: "0.9" }}>
                            <p className='fbTitle'>Roboket</p>
                            <div className='fbSponsor'>
                              <div>
                                <p className='sponsorTextInstagram'>Sponsored</p>
                              </div>
                            </div>
                          </div>

                          <div className='sponsor' style={{ flex: "0.1", display: "flex", alignItems: "center" }}>
                            Sponsored <img src={threeDot} alt='three dot' />
                          </div>
                        </div>
                        <div style={{ padding: "0px 25px", overflow: "hidden" }}>
                          {description.length === 0 ? <>This is your Description</> : <>{description}</>}
                        </div>
                        <Panel>
                          <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAd' />
                        </Panel>
                        <hr />
                        <Panel>
                          <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>
                          </div>
                          <p style={{ textAlign: "center", fontSize: "16px" }}>
                            {calltoAction === "NO_BUTTON" && <></>}
                            {calltoAction === "APPLY_NOW" && <span>Apply Now</span>}
                            {calltoAction === "BOOK_TRAVEL" && <span>Book Now</span>}
                            {calltoAction === "DOWNLOAD" && <span>Download</span>}
                            {calltoAction === "CONTACT_US" && <span>Contact Us</span>}
                            {calltoAction === "GET_OFFER" && <span>Get Offer</span>}
                            {calltoAction === "LEARN_MORE" && <span>Learn More</span>}
                            {calltoAction === "LISTEN_MUSIC" && <span>Listen Now</span>}
                            {calltoAction === "MESSAGE_PAGE" && <span>Send Message</span>}
                            {calltoAction === "SHOP_NOW" && <span>Shop Now</span>}
                            {calltoAction === "SIGN_UP" && <span>Sign Up</span>}
                            {calltoAction === "WATCH_MORE" && <span>Watch More</span>}
                            {calltoAction === "BUY_NOW" && <span>Buy Now</span>} &nbsp;
                            {">"}
                          </p>
                        </Panel>
                      </Panel>
                    )}

                    {previewState === "General Search Results (Mobile)" && (
                      <Panel shaded bordered bodyFill className='panelFormGeneral panelFormViewAd'>
                        <div className='fbSponsorNew' style={{ padding: "0px 25px" }}>
                          <div className='iconTool'>
                            <img className='circleAshColor' src={circleIcon} alt='circleAshColor' />
                            <img
                              className='flagIconStyle'
                              src={flagIcon}
                              alt='flagIcon'
                              style={{
                                position: "absolute",
                                top: 120,
                                left: 89,
                                width: "25px",
                                height: "22px"
                              }}
                            />
                          </div>

                          <div>
                            <p className='fbTitle'>Roboket</p>
                            <div className='fbSponsorNew'>
                              <div>
                                <p className='sponsorText'>Sponsored</p>
                              </div>
                              <div>
                                <img src={earthIcon} alt='earth icon' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div style={{ padding: "6px 25px", overflow: "hidden" }}>
                          {description.length === 0 ? <>Your primary text</> : <>{description}</>}
                        </div>
                        <div>
                          <img src={getDynamicImageSrc(selectedRows)} alt='file_preview' className='imgFbAdGen' />
                        </div>

                        <Panel>
                          <div>{destinationUrl.length === 0 ? "ADNDIGINET.COM" : destinationUrl}</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <p className='tilePanel'> {title.length === 0 ? "Your Title" : title}</p>

                            {calltoAction === "NO_BUTTON" && <></>}

                            {calltoAction === "APPLY_NOW" && <Button>Apply Now</Button>}

                            {calltoAction === "BOOK_TRAVEL" && <Button>Book Now</Button>}

                            {calltoAction === "DOWNLOAD" && <Button>Download</Button>}

                            {calltoAction === "CONTACT_US" && <Button>Contact Us</Button>}

                            {calltoAction === "GET_OFFER" && <Button>Get Offer</Button>}

                            {calltoAction === "LEARN_MORE" && <Button>Learn More</Button>}

                            {calltoAction === "LISTEN_MUSIC" && <Button>Listen Now</Button>}

                            {calltoAction === "MESSAGE_PAGE" && <Button>Send Message</Button>}

                            {calltoAction === "SHOP_NOW" && <Button>Shop Now</Button>}

                            {calltoAction === "SIGN_UP" && <Button>Sign Up</Button>}

                            {calltoAction === "WATCH_MORE" && <Button>Watch More</Button>}

                            {calltoAction === "BUY_NOW" && <Button>Buy Now</Button>}
                          </div>
                        </Panel>
                      </Panel>
                    )}

                    {previewState === "Marketplace Search Results (Mobile)" && (
                      <MarketPlace widthI={"width"} flag={"flag"} file={getDynamicImageSrc(selectedRows)} title={title} />
                    )}

                    {previewState === "Audience Network Interstitial" && (
                      <AudiNetWorkInter
                        flag={"flag"}
                        description={description}
                        title={title}
                        calltoAction={calltoAction}
                        file={getDynamicImageSrc(selectedRows)}
                        widthI={"width"}
                      />
                    )}

                    {previewState === "Audience Network Native" && (
                      <AudiNetWorkNative flag={"flag"} title={title} calltoAction={calltoAction} file={getDynamicImageSrc(selectedRows)} />
                    )}

                    {previewState === "Audience Network Banner" && (
                      <AudiNetBanner widthI={"width"} calltoAction={calltoAction} description={description} />
                    )}

                    {previewState === "Instagram Feed" && (
                      <InstaFeed
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        calltoAction={calltoAction}
                      />
                    )}

                    {previewState === "Instagram Stories" && (
                      <InstagramStory
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        calltoAction={calltoAction}
                        widthI={"width"}
                      />
                    )}

                    {previewState === "Instagram Explore Feed" && (
                      <InstagramStoryFeed
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        calltoAction={calltoAction}
                      />
                    )}

                    {previewState === "Instant Articles" && (
                      <InstantArticle
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        title={title}
                        calltoAction={calltoAction}
                      />
                    )}

                    {previewState === "Facebook Stories" && (
                      <InstagramStory
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        calltoAction={calltoAction}
                        widthI={"width"}
                      />
                    )}

                    {previewState === "Messenger Stories" && (
                      <MessengerStory
                        flag={"flag"}
                        file={getDynamicImageSrc(selectedRows)}
                        description={description}
                        calltoAction={calltoAction}
                        widthI={"width"}
                      />
                    )}

                    {previewState === "Marketplace Category Feed & Details Page" && (
                      <MarketPlace widthI={"width"} flag={"flag"} file={getDynamicImageSrc(selectedRows)} title={title} />
                    )}
                  </div>
                </div>
              )}

              {selectedRows?.type !== "facebook" && (
                <img src={selectedRows?.image_url} style={{ objectFit: "cover" }} height='400px' width='400px' alt='' />
              )}
            </div>

            <div className='previewImgInfo'>
              <div className='campaign-details-info-header'>
                {" "}
                <h5>Ads Details</h5>
              </div>
              <div className='previewImgInfoChild'>
                <p title={getDynamicUrl(selectedRows)} style={{ fontWeight: "600", fontSize: "16px" }}>
                  Landing Page -{" "}
                  <span style={{ fontWeight: "500" }}>
                    {" "}
                    <a style={{ color: "#2c9fdd" }} href={getDynamicUrl(selectedRows)} target='_blank'>
                      {getDynamicUrl(selectedRows).length > 30
                        ? `${getDynamicUrl(selectedRows).slice(0, 30)}...`
                        : getDynamicUrl(selectedRows)}{" "}
                    </a>{" "}
                  </span>{" "}
                </p>
                {/* <p style={{ color: "#2c9fdd" }}>
                {" "}
                
              </p> */}
                <p style={{ fontWeight: "600", fontSize: "16px" }}>
                  Size of image -{" "}
                  <span style={{ color: "#2c9fdd", fontWeight: "500" }}>
                    {selectedRows?.width}*{selectedRows?.height}
                  </span>{" "}
                </p>

                <p style={{ fontWeight: "600", fontSize: "16px" }}>
                  Status - <span style={{ color: "#2c9fdd", fontWeight: "500" }}>{selectedRows?.status} </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}></Modal.Footer>
      </Modal>
    </div>
  )
}

export default PreviewAdModal
